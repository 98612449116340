import { Component, OnInit, ElementRef, ViewChild, Input, OnChanges, SimpleChange, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { toDataURL } from 'mm-ui';
import { scrollIntoView, Utils } from 'projects/f-cms/src/app/utils/utils';
import { MediaService } from '../../../../media/media.service';
import * as uuid from 'uuid';
import { AliceService } from '../../../../alice/alice.service';

@Component({
  selector: 'media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit, OnChanges {
  id: string;
  media: any;
  border: any;
  autoplay: any;
  controls: any;
  controlsList: any;
  oncontextmenu: any;
  loop: any;
  noaudio: any;
  urlPoster: any;
  mediaType: string;
  alt: string;
  mediaPicker: any;
  spreadSheetImage: boolean = false;
  align: string;
  mediaId: string

  @ViewChild('resource', { static: true }) resource: ElementRef;
  @ViewChild('resourceVideo', { static: true }) resourceVideo: ElementRef;
  @Input('data-media') mediaData: any;
  @Input('autoStart') autoStart: any;
  @Input('timestamp') timestamp: any;
  @Input('page') page: any;
  @Output('update') update = new EventEmitter();
  dialogType: any;
  width: any;
  height: any;
  linkTo: any;
  mdSrvc: string;
  mediaInfo: any;
  iTk: any;
  start: boolean;
  src: string;
  error: boolean = false;
  urlPosterFromCDN = false;
  loading = false;
  identifier = uuid.v4();


  constructor(
    private elementRef: ElementRef,
    private mediaService: MediaService,
    private sanitizer: DomSanitizer,
    private utils: Utils,
    private aliceService: AliceService
  ) { }

  ngOnInit() {

    if (this.utils.hasStorage('mdService')) {
      this.mdSrvc = this.utils.getStorage('mdService')
    }
    this.mediaService.getIamToken().subscribe(itk => {
      this.iTk = itk;

      if (this.mediaData && this.mediaData.mediaInfo) {
        this.mediaInfo = this.mediaData.mediaInfo;
      }

    });

    this.getMedia();
  }

  ngAfterViewInit(): void {
    this.getMedia();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.timestamp && !!changes.timestamp.currentValue) {


      const waitForPlayer = () => {
        const player: any = document.getElementById('video_' + this.id + '_' + this.identifier);
        if (player) {
          player.currentTime = this.convertTimestampToSeconds(this.timestamp);

          setTimeout(() => {
            player.play();
            this.update.emit({ type: 'scroll', player });
          }, 100);
        } else {
          setTimeout(() => waitForPlayer(), 100);
        }
      }

      waitForPlayer();
    }
  }

  convertTimestampToSeconds(timestamp: string) {
    const [minutes, seconds] = timestamp?.split(":") || [0, 0];

    return +minutes * 60 + +seconds;
  }


  getMedia() {
    this.spreadSheetImage = this.elementRef.nativeElement.dataset.spreadsheetimage
    this.id = this.mediaInfo && this.mediaInfo.id ? this.mediaInfo.id : this.elementRef.nativeElement.id;
    this.mediaId = this.mediaInfo && this.mediaInfo.mediaId ? this.mediaInfo.mediaId : null;
    this.alt = this.mediaInfo && this.mediaInfo.alt ? this.mediaInfo.alt : this.elementRef.nativeElement.alt;
    this.mediaPicker = this.mediaInfo && this.mediaInfo.mediaPicker ? this.mediaInfo.mediaPicker : this.elementRef.nativeElement.dataset ? this.elementRef.nativeElement.dataset.mediapicker : true;
    this.border = this.mediaInfo && this.mediaInfo.border ? this.mediaInfo.border : this.elementRef.nativeElement && this.elementRef.nativeElement.dataset ? this.elementRef.nativeElement.dataset.border : '';
    this.height = this.mediaInfo && this.mediaInfo.height ? this.mediaInfo.height : this.elementRef.nativeElement && this.elementRef.nativeElement.dataset ? this.elementRef.nativeElement.dataset.height : '';
    this.width = this.mediaInfo && this.mediaInfo.width ? this.mediaInfo.width : this.elementRef.nativeElement && this.elementRef.nativeElement.dataset ? this.elementRef.nativeElement.dataset.width : '';
    this.dialogType = this.mediaInfo && this.mediaInfo.dialogType ? this.mediaInfo.dialogType : this.elementRef.nativeElement.dataset ? (this.elementRef.nativeElement.dataset.type ? this.elementRef.nativeElement.dataset.type : false) : false;
    this.linkTo = this.mediaInfo && this.mediaInfo.linkTo ? this.mediaInfo.linkTo : this.elementRef.nativeElement.dataset ? (this.elementRef.nativeElement.dataset.link != null && this.elementRef.nativeElement.dataset.link != 'undefined' && this.elementRef.nativeElement.dataset.link != 'null' ? this.elementRef.nativeElement.dataset.link : false) : false;
    this.controlsList = this.mediaInfo && this.mediaInfo.nodownload ? 'nodownload' : this.elementRef.nativeElement.dataset && this.elementRef.nativeElement.dataset.nodownload ? 'nodownload' : 'download';
    this.oncontextmenu = this.mediaInfo && this.mediaInfo.oncontextmenu ? this.mediaInfo.oncontextmenu : this.elementRef.nativeElement.dataset && this.elementRef.nativeElement.dataset.oncontextmenu ? this.elementRef.nativeElement.dataset.oncontextmenu : 'return true';
    this.align = this.mediaInfo && this.mediaInfo.align ? this.mediaInfo.align : '';
    this.noaudio = this.mediaInfo && this.mediaInfo.noaudio ? this.mediaInfo.noaudio : this.elementRef.nativeElement.dataset ? (this.elementRef.nativeElement.dataset.noaudio != null && this.elementRef.nativeElement.dataset.noaudio != 'undefined' && this.elementRef.nativeElement.dataset.noaudio != 'null' ? this.elementRef.nativeElement.dataset.noaudio : false) : false;
    this.loop = this.mediaInfo && this.mediaInfo.loop ? this.mediaInfo.loop : this.elementRef.nativeElement.dataset ? (this.elementRef.nativeElement.dataset.loop != null && this.elementRef.nativeElement.dataset.loop != 'undefined' && this.elementRef.nativeElement.dataset.loop != 'null' ? this.elementRef.nativeElement.dataset.loop : false) : false;
    this.urlPoster = this.mediaInfo && this.mediaInfo.urlPoster ? this.mediaInfo.urlPoster : '';

    if (this.urlPoster && (this.urlPoster.indexOf('://cdn.') >= 0 || this.urlPoster.indexOf('://storage-mmp5privatebucket') >= 0)) {
      this.urlPosterFromCDN = true;
    }

    if (this.linkTo && this.linkTo.indexOf('://cdn.') >= 0) {
      this.mediaService.getCDNImage(this.linkTo, this.iTk).subscribe((binarie: any) => {

        let file = toDataURL(binarie);

        if (file) {

          file.then((data: any) => {

            const blob = this.convertBase64ToBlob(data.result);
            const blobUrl = URL.createObjectURL(blob);
            this.linkTo = this.sanitizer.bypassSecurityTrustUrl(blobUrl);


          });
        };
      });
    }


    if (!this.mediaPicker && this.dialogType) {
      let content_type = this.dialogType == 'video' ? `${this.dialogType}/${this.id.split('.').reverse()[0]}` : (this.dialogType == 'audio' ? `${this.dialogType}/mpeg` : this.dialogType);

      this.createMediaElement({
        resource_file: {
          content_type,
          is_image: this.dialogType == 'image',
          url: this.mediaId ? this.mediaId : this.id
        }
      })
    } else {
      let id = this.mediaId ? this.mediaId : this.id;
      if (id) {
        this.mediaService.getMediaById(id).subscribe(media => {
          this.mediaService.getMedia(id).subscribe((res: any) => {
            media.resource_file = { ...media.resource_file, ...res }
            this.createMediaElement(media)
          })
        });
      }
    }

    if (this.mediaId) {
      this.aliceService.addCurrentMedias(this.mediaId);
    }
  }

  convertBase64ToBlob(base64Image: string) {
    const parts = base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    // so deus sabe pq tava quebrando quando baixa esse mimetype
    const uInt8Array = new Uint8Array(imageType === 'application/vnd.ms-excel.sheet.macroenabled.12' ? decodedData.length - 1 : decodedData.length);

    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    };

    return new Blob([uInt8Array], { type: imageType });
  };

  openMedia(url) {

    this.mediaService.getCDNImage(url, this.iTk).subscribe((binarie: any) => {

      let file = toDataURL(binarie);

      if (file) {

        file.then((data: any) => {

          const blob = this.convertBase64ToBlob(data.result);
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        });
      };

    })
  }


  createMediaElement(media: any): void {
    this.media = media.resource_file
    this.mediaType = this.media && this.media.content_type.indexOf('audio') >= 0 ? 'audio' : this.media && this.media.content_type.indexOf('video') >= 0 ? 'video' : 'image';

    if (this.autoStart) {
      this.update.emit(true);
      this.startMedia('video', this.media);
    }
  }

  startMedia(type, media) {
    this.loading = true;

    let thePlayer: any = type == 'video' ? document.getElementById('video_' + media.id + '_' + this.identifier) : document.getElementById('audio_' + media.id);
    // let theContainer = document.getElementById('container_' + media.id)

    if (thePlayer && type == 'video' && media?.mimeCodec != null) {
      this.start = true;
      this.update.emit(true);

      let seconds = 0;

      if (this.timestamp) {
        seconds = this.convertTimestampToSeconds(this.timestamp);
      }

      this.mediaService.getCDNVIdeo(media, this.page, this.iTk, thePlayer, seconds, (currentTime) => {
        this.loadFullMedia(media, thePlayer, currentTime);
      })
    } else {
      this.loadFullMedia(media, thePlayer);
    }

  }

  loadFullMedia(media, thePLayer?, currentTime?) {
    this.mediaService.getCDNImage(media.url, this.iTk).subscribe({
      next: (binarie: any) => {
        if (binarie && !binarie.error) {

          let file = toDataURL(binarie);

          if (file) {

            file.then((data: any) => {

              const blob = convertBase64ToBlob(data.result);
              const blobUrl = URL.createObjectURL(blob);

              function convertBase64ToBlob(base64Image: string) {
                const parts = base64Image.split(';base64,');
                const imageType = parts[0].split(':')[1];
                const decodedData = window.atob(parts[1]);
                // so deus sabe pq tava quebrando quando baixa esse mimetype
                const uInt8Array = new Uint8Array(imageType === 'application/vnd.ms-excel.sheet.macroenabled.12' ? decodedData.length - 1 : decodedData.length);

                for (let i = 0; i < decodedData.length; ++i) {
                  uInt8Array[i] = decodedData.charCodeAt(i);
                };

                return new Blob([uInt8Array], { type: imageType });
              };

              // thePlayer.getElementsByTagName('source')[0].src = blobUrl;
              this.start = true;

              this.src = blobUrl;

              if (thePLayer) {
                thePLayer.src = blobUrl;

                if (currentTime) {
                  thePLayer.currentTime = currentTime;
                }
              }

              this.update.emit(true);
              // thePlayer.load();
              // thePlayer.autoplay = true;
            });
          }
        } else if (binarie && binarie.error) {
          this.error = true;
          let error = binarie.error
          if (error.status == '404') {
            console.log('mídia não encontrada', media.url)
          }
        }
      },
      error: (err) => console.log({ 'mediaShowError': err })
    });
  }
}

