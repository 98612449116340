export const environment = {
  production: false,
  recaptcha: {
    key: "6Le2qBEpAAAAAPbFDfubdAHprBnAWcYUMab9BTlQ",
    disabled: false,
    url: "https://www.google.com/recaptcha/enterprise.js"
  },
  endpoint: 'statement',
  chatWS: 'https://chat.ws.tst.mmcafe.corp',
  firebase: {
    apiKey: "AIzaSyDXwKWt9bJqqhr0bGShftaCtntYCKG-qLs",
    authDomain: "gcdev---mmp5.firebaseapp.com",
    projectId: "gcdev---mmp5",
    storageBucket: "gcdev---mmp5.firebasestorage.app",
    messagingSenderId: "128276691700",
    appId: "1:128276691700:web:a5127e603d2bb68e78fca1",
    measurementId: "G-TT29JT52YP"
  }
};
