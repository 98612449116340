import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "../../app.config";
import { Menu } from "../../models/menu";
import { Page, PagesPageable, SiteMapPages } from "../../models/page";
import { Store } from "@ngrx/store";
import { BehaviorSubject, of } from "rxjs";
import { tap, map } from "rxjs/operators";
import { Birthdays } from "../../models/widgets";
import { HomepageDefinitionState } from "../cms/cms.state";
import { MediaService } from "../media/media.service";
import { MediaContent } from "../../models/Media";
import { LoggedUser } from "../../models/loggedUser.service";
import { Flowsurvey } from "../../interfaces/flowsurvey";
import { PlatformConfigService } from "../platform-config/platform-config.service";


@Injectable({ providedIn: 'root' })
export class ViewService {

  apiVersion = `v${AppConfig.data['apiVersion']}`;
  service = AppConfig.data['services']['cms'];
  serviceUser = AppConfig.data['services']['user'];
  endpoint = `${this.service}/${this.apiVersion}`
  endpointUser = `${this.serviceUser}/${this.apiVersion}`
  pageView$: BehaviorSubject<Page> = new BehaviorSubject({} as Page)
  blockCopy$: BehaviorSubject<any> = new BehaviorSubject({})

  constructor(
    private _http: HttpClient,
    public store: Store<HomepageDefinitionState>,
    private _mediaService: MediaService,
    private _user: LoggedUser,
    private _platformConfig: PlatformConfigService
  ) { }

  getMenuItems(id: string) {
    return this._http.get<Menu>(`${this.endpoint}/homepages/${id}/menu`)
  }

  getPageView(id: string, homepageId?: string, menuId?: string, preview?: string) {
    let hasPreview = !!preview ? 'preview' : 'view';
    return this._http.get<Page>(`${this.endpoint}/pages/${homepageId ? homepageId + '/' : ''}${menuId ? menuId + '/' : ''}${id ? id + '/' : ''}${hasPreview}`).pipe(tap(page => this.pageView$.next(page)))
  }

  completePageView(menuId?: string) {
    return this._http.post<Page>(`${this.endpoint}/pages/${menuId}/view`, {});
  }

  getPageViewObj() {
    return this.pageView$.asObservable()
  }

  updatePage(page: Page) {
    this.pageView$.next(page)
  }

  searchPageView(queryTerm, size, page, homePageId: string, startAt?: any, endAt?: any, userId?: any) {
    if (!userId) {
      userId = this._user.getUserId();
    }
    return this._http.post<PagesPageable>(`${this.endpoint}/pages/search`, { queryTerm, endAt, startAt, userId, homePageId }, { params: { page, size } })
  }

  searchPageViewWithBreadcrumb(queryTerm, size, page, homePageId: string, startAt?: any, endAt?: any, userId?: any) {
    if (!userId) {
      userId = this._user.getUserId();
    }
    return this._http.post<PagesPageable>(`${this.endpoint}/pages/search/breadcrumb`, { queryTerm, endAt, startAt, userId, homePageId }, { params: { page, size } })
  }

  areaSearch(params) {
    return this._http.post<PagesPageable>(`${this.endpoint}/pages/area/search/breadcrumb`, params, { params: { page: params.page, size: params.size } })
  }

  searchUsersView(params) {
    return this._http.post<PagesPageable>(`${this.endpoint}/users/search/main`, params, { params: { page: params.page, size: params.size } })
  }

  searchMediasView(queryTerm: any, size?: any, page?: any, startAt?: string, endAt?: string) {
    return this._http.post<MediaContent>(`${this.endpoint}/medias/search`, { startAt, endAt, queryTerm }, { params: { page, size } })
  }

  setReadingData(params) {
    return this._http.post<any>(`${this.endpoint}/pages/readingdata/create`, params)
  }

  getReadingDateReport(id: string) {
    return this._http.get(`${AppConfig.data['services']['gateway']}/download/cms/${this.apiVersion}/reading/report/${id}`, { observe: 'response', responseType: 'blob' })
  }


  getPage() {
    return this.pageView$.asObservable()
  }

  getBirthdays(widgetId: string, size: string, page: string, hiring?: boolean) {
    let API_URL = `${this.endpoint}/widgets/birthdays/${hiring ? 'hiring/' : ''}${widgetId}`
    return this._http.get<Birthdays>(API_URL, { params: { size, page } })
  }

  getDayWithBirthdays(widgetId: string, month: string, year: string, hiring: boolean) {
    let API_URL = `${this.endpoint}/widgets/birthdays/${hiring ? 'hiring/' : ''}month/${widgetId}`;
    return this._http.get<number[]>(API_URL, { params: { month, year } })
  }

  getBirthdaysByDay(widgetId: string, day: string, month: string, year: string, hiring: boolean, page?: string) {
    let API_URL = `${this.endpoint}/widgets/birthdays/${hiring ? 'hiring/' : ''}day/${widgetId}`;
    return this._http.get<Birthdays>(API_URL, { params: { month, day, year, page } })
  }

  getBirthdaysByPage(page: number, limit: number) {
    let API_URL = `http://5d3b07b2552bfb00148e0003.mockapi.io/api/v1/birthdays?page=${page}&limit=${limit}`
    return this._http.get<any>(`${API_URL}`)
  }

  saveFormContent(config) {
    let headers = new HttpHeaders().append('accept', 'application/json').append('content-type', 'application/json');
    return this._http.post(`${this.endpoint}/form/data/create/`, config, { headers })
  }

  sendFormMail(config) {
    return this._http.post(`${this.endpoint}/mail/send`, config)
  }

  sendPageComments(id: string, menuId: string, comment: string, listMentionedUsers: string[], parentId?: string) {
    return this._http.post(`${this.endpoint}/pages/comment/${id}`, { text: comment, menuId, listMentionedUsers: listMentionedUsers, parentId })
  }

  listCommentsOfAPage(id: string, params) {
    return this._http.get(`${this.endpoint}/pages/comment/${id}/view`, { params })
  }

  removeCommentLoggedUser(id, commentId) {
    return this._http.delete(`${this.endpoint}/pages/comment/user/${id}/${commentId}`)
  }

  removeComment(id, commentId) {
    return this._http.delete(`${this.endpoint}/pages/comment/${id}/${commentId}`)
  }

  getCommentReplies(id, params){
    console.log({params})
    return this._http.get(`${this.endpoint}/pages/comment/${id}/replies`, { params })
  }

  addCommentReaction(reactionType: string, targetType: string, targetId: string) {
    return this._http.post(`${this.endpoint}/reaction/add/${reactionType}/${targetType}/${targetId}`, {})
  }
  removeCommentReaction(reactionType: string, targetId: string) {
    return this._http.delete(`${this.endpoint}/reaction/remove/${reactionType}/${targetId}`)
  }

  getCommentReactions(targetId: string) {
    return this._http.get(`${this.endpoint}/reaction/stats/${targetId}`)
  }


  getSiteMap(id: string) {
    return this._http.get<SiteMapPages[]>(`${this.endpoint}/sitemap/homePage/${id}`);
  }

  getSiteMapPage(id: string, homeid: string) {
    return this._http.get<SiteMapPages[]>(`${this.endpoint}/sitemap/homePage/${homeid}/menu/${id}`);
  }

  getFlowsurvey(id) {
    return this._http.get<Flowsurvey>(`${this.endpoint}/flowsurvey/${id}`)
  }

  getCustomEmailPreview(params) {
    return this._http.post<any>(`${this.endpoint}/pages/email/preview`, params)
  }

  submitCustomEmail(params) {
    return this._http.post<any>(`${this.endpoint}/pages/email/customized/create`, params)
  }

  htmlContent(id) {
    return this._http.get(`${this.endpoint}/pages/html/content/${id}`, { responseType: 'text' })
  }

  getPageRedirect(menuManagementId, homePageId) {
    return this._http.get<Page>(`${this.endpoint}/pages/${menuManagementId}/viewInHomepage/${homePageId}`).pipe(tap(page => this.pageView$.next(page)))
  }

  getPageListPaginated(pageId: string, featureId: string, page: number, size: number) {
    return this._http.get<any>(`${this.endpoint}/pages/pageListPages/${pageId}/${featureId}`, { params: { size, page } })
  }

  mainSearch(params: any) {
    return this._http.post<any>(`${this.endpoint}/custom/search/main`, params, { params: { size: params.size, page: params.page } })
  }

  getBlockCopyOnView() {
    return this.blockCopy$.asObservable()
  }

  updateBlockCopyOnView(blockCopy) {
    this.blockCopy$.next(blockCopy)
  }

  saveNoClientPageAccsses(url: string) {
    return this._http.post(`${this.endpoint}/homepages/url/noclient`, url)
  }
}
