import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NotifyService } from '../../services/notify.service';
import { Notify, PageableNotify } from '../../interfaces/Notify';
import { fromEvent, Subscription } from 'rxjs';
import { skip, filter } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NotifyTrayListComponent } from './notify-tray-list/notify-tray-list.component';

@Component({
  standalone:true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NotifyTrayListComponent
  ],
  selector: 'mm-notify-tray',
  templateUrl: './notify-tray.component.html',
  styleUrls: ['./notify-tray.component.scss']
})
export class NotifyTrayComponent implements OnInit {

  @Output('close') close = new EventEmitter();

  notifys: Notify[];
  activitiesNotify: Notify[];
  resp: any;
  unviewedNotifications: any = {
    activities: 0,
    default: 0
  };
  readedBellNotify$: Subscription;
  newBellNotify$: Subscription;
  active = 1;

  constructor(
    private notifyService: NotifyService
  ) { }

  ngOnInit() {
    this.getNotifications();
    this.listenToOutClick();
  }

  listenToOutClick() {
    let events$ = fromEvent(document, 'click');

    events$.pipe(
      skip(1),
      filter((e: any) => e.path.filter(item => item.className == 'notify-tray-content').length == 0))
      .subscribe(() => this.close.emit(true))
  }

  getNotifications() {

    this.unviewedNotifications = {
      activities: this.notifyService.bellActivitiesNotifications,
      default: this.notifyService.bellDefaultNotifications
    }

    this.readedBellNotify$ = this.notifyService.getReadBellNotification()
      .subscribe(id => id === 'all' ? this.unviewedNotifications = {
        activities: 0,
        default: 0
      } : {
        activities: this.notifyService.bellActivitiesNotifications || 0,
        default: this.notifyService.bellDefaultNotifications || 0
      });

    this.newBellNotify$ = this.notifyService.getSocketNotifys().subscribe(notify => {
      if (notify.activity) {
        this.unviewedNotifications.activities++
      } else {
        this.unviewedNotifications.default++
      }
    });

    this.notifyService.getTrayDefaultNotifys('10').subscribe(notifys => this.formatNotify(notifys))
    this.notifyService.getTrayActivitiesNotifys('10').subscribe(acnotifys => this.formatAcNotify(acnotifys))
  }

  formatNotify(notifys: PageableNotify): void {
    this.resp = notifys;
    this.notifys = notifys.content;
  }

  formatAcNotify(acnotifys: PageableNotify): void {
    this.resp = acnotifys;
    this.activitiesNotify = acnotifys.content;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.readedBellNotify$ && this.readedBellNotify$.unsubscribe();
    this.newBellNotify$ && this.newBellNotify$.unsubscribe();
  }
}

