<div [ngClass]="[
  this.align == 'center' ? 'd-flex justify-content-center' : '',
 this.align == 'right' ? 'pull-right ml-1' : '',
  this.align == 'left' ? 'pull-left mr-1' : ''
]">

  <a *ngIf="linkTo" [href]="linkTo" target="_blank">
      <img [alt]="alt" *ngIf="media?.is_image" class="img-fluid 1" [ngStyle]="{'border': this.border+' solid black', 'width': this.width+'px', 'height': this.height+'px'}" [src]="media?.url | authImage:'image':iTk:mdSrvc | async" />
  </a>

  <img [alt]="alt" *ngIf="media?.is_image && spreadSheetImage" (click)="openMedia(media?.url)" class="img-fluid link-image" [ngStyle]="{'border': this.border+' solid black', 'width': this.width+'px', 'height': this.height+'px'}" [src]="media?.url | authImage:'image':iTk:mdSrvc | async" />

  <img [alt]="alt" *ngIf="media?.is_image && !linkTo && !spreadSheetImage" class="img-fluid 2" [ngStyle]="{'border': this.border+' solid black', 'width': this.width+'px', 'height': this.height+'px'}" [src]="media?.url | authImage:'image':iTk:mdSrvc | async" />

</div>

<div *ngIf="media && media.id && media?.content_type?.indexOf('video') >= 0" class="video-content" #resourceVideo>
  <div
    [id]="'container_'+media.id"
    class="container_video"
    [ngStyle]="{'display':start ? 'none' : 'block', 'color': urlPoster ? 'white' : 'unset', 'height': height ? height + '%' : 'unset', 'width': width && width < 100 ? width + '%' : 'unset' }"
    (click)="startMedia('video', media)">
    <img *ngIf="urlPoster" class="img-poster" [src]="urlPosterFromCDN ?  (urlPoster | authImage:'image':iTk:mdSrvc | async) : urlPoster" />
    <div class="file">
      <!-- <i class="fa fa-file-movie-o"></i> -->
      <mmp5-mm-icons class="file-type" [icon]="['fa-regular','fa-file-video']"></mmp5-mm-icons>

      <h5 [ngClass]="[urlPoster ? 'white' : '']">{{media.title}}</h5>
    </div>

    <div class="play">
      <!-- <i class="fa fa-play-circle"></i> -->
      <mmp5-mm-icons *ngIf="!loading" class="play-button" [icon]="['fa-solid','fa-circle-play']"></mmp5-mm-icons>
      <mmp5-mm-icons *ngIf="loading" class="loading-button" [icon]="['fa-solid','fa-spinner']"></mmp5-mm-icons>
    </div>
  </div>

  <!-- autoStart é utilizado na Alice, para não iniciar o video automaticamente, mas tbm não exibir o cover container -->
  <video
    preload="none"
    controls
    *ngIf="media && media?.content_type?.indexOf('video') >= 0"
    [id]="'video_'+ media.id + '_' + identifier"
    [controlsList]="this.controlsList"
    [attr.contextmenu]="this.oncontextmenu"
    [autoplay]="!autoStart"
    [muted]="noaudio"
    [loop]="loop"
    [ngStyle]="{'width': '100%', 'display':start ? 'block' : 'none'}">
        <source [src]="src" [type]="media?.content_type">
        Seu navegador não suporta exibição de video.
  </video>
</div>

<div *ngIf="media && media.id && media?.content_type?.indexOf('audio') >= 0" class="audio-content" #resource>

  <div
    [id]="'container_'+media.id"
    class="container_audio"
    (click)="startMedia('audio', media)"
    [ngStyle]="{'width': '100%', 'display':start ? 'none' : 'block'}">

    <div class="file">
      <!-- <i class="fa fa-file-sound-o"></i> -->
      <mmp5-mm-icons class="file-type" [icon]="['fa-regular','fa-file-audio']"></mmp5-mm-icons>
      <h5 *ngIf="media">{{media.title}}</h5>
    </div>

    <div class="play">
      <!-- <i class="fa fa-play-circle"></i> -->
      <mmp5-mm-icons class="play-button" [icon]="['fa-solid','fa-circle-play']"></mmp5-mm-icons>
    </div>
  </div>

  <audio
    *ngIf="media && media?.content_type?.indexOf('audio') >= 0 && src"
    [ngStyle]="{'display':start ? 'block' : 'none'}"
    class="audio-tag"
    [id]="'audio_'+media.id"
    [autoplay]="!autoStart"
    controls>
      <source [src]="src" [type]="media?.content_type">
      Seu navegador não suporta exibição de áudio.
  </audio>
</div>
