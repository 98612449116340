import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Notify, NotifyLinkType } from '../../../interfaces/Notify';
import { MediaService } from '../../../components/media/media.service';
import { of, zip, forkJoin } from 'rxjs';
import { mergeAll, first, catchError, map } from 'rxjs/operators';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { NotifyService } from '../../../services/notify.service';
import { Utils, whichProtocol } from "../../../utils/utils";
import { CommonModule } from '@angular/common';
import { MMIconsModule, SafePipeModule, TooltipModule } from 'mm-ui';
import { NgScrollbarModule } from 'ngx-scrollbar';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MMIconsModule,
    TooltipModule,
    NgScrollbarModule,
    SafePipeModule
  ],
  providers: [
    MediaService
  ],
  selector: 'mm-notify-tray-list',
  templateUrl: './notify-tray-list.component.html',
  styleUrls: ['./notify-tray-list.component.scss']
})
export class NotifyTrayListComponent implements OnInit {

  @Input('items') items: Notify[];
  @Input('type') type: string;
  @Output('closed')closed = new EventEmitter();
  mdSrvc: string;
  iTk: any;

  constructor(
    private mediaService: MediaService,
    private router: Router,
    private notifyService: NotifyService,
    private utils: Utils
  ) { }

  ngOnInit() {
    if(this.utils.hasStorage('mdService')){
      this.mdSrvc = this.utils.getStorage('mdService')
    }
    this.mediaService.getIamToken().subscribe(itk => this.iTk = itk);
    // moment.locale('pt-br');
  }

  notifyAction(notify: Notify){



    if(!notify.viewed){
      this.notifyService.viewNotify(notify.id, this.type).subscribe(() => {});
    }

    if(notify.notifyInteraction.type == NotifyLinkType.none) {
      this.router.navigate(['/hp', 'notify', 'all'])
    } else {
      if(notify.notifyInteraction.type == NotifyLinkType.page){
        this.router.navigate(['/hp', 'page', notify.notifyInteraction.pageId])
      } else {
        let domain = '';
        if(notify.notifyInteraction.url.indexOf('http://') <= -1 && notify.notifyInteraction.url.indexOf('https://') <= -1){
          domain = whichProtocol();
          window.open(`${domain}${notify.notifyInteraction.url}`);
        }else{
          window.open(notify.notifyInteraction.url);
        }
      }
    }
    this.closed.emit(true);
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if(changes.items && !changes.items.firstChange && this.items && this.items.length > 0){
  //     this.getIcons();
  //   }
  // }

  formatDate(date){
    return moment(date, 'DD/MM/YYYY HH:mm').fromNow();
  }

  getIcons() {
    let mediaIcons$ = forkJoin(this.items.map(item => this.mediaService.getMediaById(item.ico).pipe(catchError(() => of({resource_file: {}})), first(), map(resp => resp.resource_file))));
    mediaIcons$.subscribe(medias => {
      this.items = this.items.map((item, i) => ({...item, ico: medias[i]}))
    })
  }
}
