import { Component, OnInit, Input, SimpleChanges, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { toDataURL } from 'mm-ui';
import { ImageGalleryConfig } from 'projects/f-cms/src/app/interfaces/featuresConfig';
import { MediaService } from '../../../media/media.service';
import { Utils } from "../../../../utils/utils";
const lightGallery = window['lightGallery'];

@Component({
  selector: 'page-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {

  @Input('feature') feature: ImageGalleryConfig;
  @Input('htmlPlus') htmlPlus: boolean
  @Input('images') images: any[];
  @Input('title') title: string;
  @Input('size') size: string;
  @Input('position') position: string;
  @Input('featureItem') featureItem = true;

  @ViewChild('light', { static: true }) light: ElementRef;

  galleryControl: boolean

  configTile = {
    grid: { xs: 3, sm: 4, md: 5, lg: 5, all: 0 },
    interval: { timing: 500000, initialDelay: 500000 },
    loop: false,
    touch: true,
    velocity: 0.2
  }

  configTileZoom = {
    grid: { xs: 4, sm: 5, md: 6, lg: 7, all: 0 },
    interval: { timing: 500000, initialDelay: 500000 },
    loop: false,
    touch: true,
    velocity: 0.2
  }

  startSlide: number;
  openPopUp: boolean;
  imgs = []
  mdSrvc: string = ''
  iTk: any

  constructor(
    private _renderer2: Renderer2,
    private _mediaService: MediaService,
    private utils: Utils
  ) { }

  log(e: any) {

    let div = this._renderer2.createElement('div');

    if (this.light && lightGallery) {

      lightGallery(div, {
        dynamic: true,
        thumbnail: true,
        animateThumb: true,
        download: false,
        index: parseInt(e),
        dynamicEl: this.imgs.sort((a, b) => this.ordenateArray(a, b))
      })

    }
  }

  ngOnInit() {
    if (this.utils.hasStorage('mdService')) {
      this.mdSrvc = this.utils.getStorage('mdService')
    };
    this._mediaService.getIamToken().subscribe(itk => {
      console.log(itk)
      this.iTk = itk
      this.filterImages();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.images && !changes.images.firstChange) {
      this.filterImages();
    }
  }

  filterImages() {

    this.images = this.images.filter(media => media.mediaUrl);
    this.images.forEach(img => {

      this._mediaService.getCDNImage(img.mediaUrl, this.iTk).subscribe({
        next: (binarie: any) => {
          let file = toDataURL(binarie)
          if (file) {
            file.then((res: any) => {

              this.imgs.push({
                "src": res.result,
                'thumb': res.result,
                'subHtml': `<div>
                            <p style="color: white">${img.mediaTitle}</p>
                            <p style="color:white">${img.mediaDescription ? img.mediaDescription : ''}</p>
                          </div>`,
                'order': img.order
              })
            })

          }
        },
        error: (err) => {

          console.log({ midia_ERRO: err });
          this.imgs.push({
            "src": '',
            'thumb': '',
            'subHtml': `<div>
                          <p style="color: white">${img.mediaTitle}</p>
                          <p style="color:white">${img.mediaDescription ? img.mediaDescription : ''}</p>
                        </div>`
          })

        }

      })
    })
  }

  ordenateArray(a, b) {


    if (a.order > b.order) {
      return 1;
    }
    if (a.order < b.order) {
      return -1;
    }

    return 0;


  }

}
