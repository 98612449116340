import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AppConfig } from "../app.config";
import { ChatService } from "../components/chat/chat.service";
import { ContentMenuService } from "../components/cms/content/menu/content-menu.service";
import { PlatformConfigService } from "../components/platform-config/platform-config.service";
import { GlossaryHighlightService } from "../components/view/dynamic-html/glossary-highlight.service";
import { Notify } from "../interfaces/Notify";
import { EventType } from "../models/events.model";
import { LoggedUser } from "../models/loggedUser.service";
import { b64ToBLob } from "../utils/utils";
import { NotifyService } from "./notify.service";
import { EventSourceMessage, fetchEventSource } from '@microsoft/fetch-event-source';

@Injectable()
export class EventsService {
  private apiVersion = `v${AppConfig.data['apiVersion']}`;
  private service = AppConfig.data['services']['events'];
  private endpoint = `${this.service}/${this.apiVersion}`
  private authLogout = new Subject<boolean>();
  private chatMessage = new Subject<any>();
  private eventSource: EventSource;
  private connectedStatus = false;

  constructor(
    private loggedUser: LoggedUser,
    private notifyService: NotifyService,
    private platformConfigService: PlatformConfigService,
    private glossaryHighlightService: GlossaryHighlightService,
    private contentMenuService: ContentMenuService,
  ) { }


  listenToEvents() {
    const token = this.loggedUser.getToken();

    if (token && !this.eventSource) {
      fetchEventSource(`${this.endpoint}/consumer/events`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        openWhenHidden: true,
        onmessage: (message: EventSourceMessage) => {
          switch (message.event as EventType) {
            case EventType.NOTIFICATION:
              return this.sendNotification(message);
            case EventType.LGPD_UPDATED:
              return this.showLgpdModal();
            case EventType.SESSION_EXPIRED:
              return this.authLogout.next(true)
            case EventType.GLOSSARY_UPDATED:
              return this.glossaryHighlightService.getTermsFromBackend()
            case EventType.SIDEBAR_MENU_UPDATED:
              return this.updateSideMenu(message)
            case EventType.CHAT_MESSAGE:
              return this.chatMessage.next(message);
            case EventType.FINISHED_PROCESS_REPORT:
              return this.downloadFile(JSON.parse(message.data));
          }
        },
        onerror: () => {
          this.connectedStatus = false
        }
      })
      this.connectedStatus = true;
    }
  }

  getConnectedStatus() {
    return this.connectedStatus;
  }

  downloadFile(data: any) {
    const base64 = data.base64;
    const filename = data.filename;
    const contentType = data.contentType;

    const blob = b64ToBLob(base64, contentType);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  }

  sendNotification(message: EventSourceMessage) {
    const notification = JSON.parse(message.data) as Notify;

    this.notifyService.emitNotification(notification)
  }

  showLgpdModal() {
    this.platformConfigService.getAndShowTerm();
  }

  listenToLogout() {
    return this.authLogout.asObservable();
  }

  updateSideMenu(message: EventSourceMessage) {
    const { menuId, homepageId } = JSON.parse(message.data);

    if (menuId) {
      return this.contentMenuService.updateMenu(menuId, 'MENUID');
    }

    if (homepageId) {
      this.contentMenuService.updateMenu(homepageId, 'HOMEPAGE')
    }
  }

  sendChatMessage(m: MessageEvent) {
    const data = JSON.parse(m.data);

    this.chatMessage.next(data);
  }

  listenToChatMessage() {
    return this.chatMessage.asObservable();
  }
}
