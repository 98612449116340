<div *ngIf="showHomepagesOnNavbar" class="fake-navbar"></div>
<nav *ngIf="showHomepagesOnNavbar" class="homepages-navbar">
  <span *ngFor="let home of homepagesUserHasAccess" (click)="redirect(home?.id)" [ngClass]="[currentHome == home?.id ? 'active' : '']">{{home?.title}}</span>
</nav>
<nav *ngIf="loggedUser" (mouseleave)="dropbox=false; showHomepageList = false" [ngClass]="['navbar navbar-header fixed-top justify-content-between mmp-nav ms-4 mmp-nav--left', showHomepagesOnNavbar ? 'show-homepages' : '']">
  <div [ngClass]="['container large nv', loggedUser?.roles?.length == 0 ? 'remove-margin-left' : '']" *ngFor="let navbar_item of navbar_items">
        <div class="form-inline principal">
            <div (mouseenter)="showHomepages()" class="link-homepage" *ngIf="!showHomepagesOnNavbar">
                <a class="navbar-link" (click)="gotoHomepage()">
                    <mmp5-mm-icons [icon]="['icon','icon-home']" [ngStyle]="{'color': 'var(--navbar-subtitle-color)'}"></mmp5-mm-icons>
                </a>
                <mm-navbar-homepage-list
                    *ngIf="showHomepageList"
                    (mouseenter)="showHomepageList = true"
                    [homepages]="homepagesUserHasAccess"
                    (mouseleave)="showHomepageList = false"
                    (fecharMenu)="onFecharMenu()"
                >
                </mm-navbar-homepage-list>
            </div>
            @if(hideSitemap && hideSitemap == 'exibir'){
              <div class="link-homepage">
                  <a class="navbar-link" (click)="gotoSiteMap()">
                      <mmp5-mm-icons [icon]="['icon','icon-grupos-fluxo']" [ngStyle]="{'color': 'var(--navbar-subtitle-color)'}"></mmp5-mm-icons>
                  </a>
              </div>
            }
            <div class="search-bar">
                <div class="position-relative search-bar-input" *ngIf="!searchConfig || (searchConfig.searchPage || searchConfig.searchUser || searchConfig.searchMedia)">
                    <label for="search" [ngClass]="['label-input-search', active || search.value ? 'focus' : '']"> {{ 'cms.features.html.Find' | translate }} </label>
                    <input id="search" [formControl]="search" autocomplete="off" (focus)="active = true" (keyup)="emitKeyPress($event)" class="input-search-bar"/>
                    <div [ngClass]="['pointer', 'search-icon-nav', active || search ? 'focus' : '']">
                        <!-- <em (click)="searchItem({key: 'Enter'})" class="fa fa-search"></em> -->
                        <mmp5-mm-icons (click)="searchItem({key: 'Enter'})" class="fas-search" [icon]="['fa-solid','fa-magnifying-glass']"></mmp5-mm-icons>
                    </div>
                    <mm-navbar-autosuggest-list [keyword]="search?.value" [showSuggestList]="showSuggestList" [search-config]="searchConfig"></mm-navbar-autosuggest-list>
                </div>
            </div>
        <!-- </div>

        <div class="form-inline"> -->
            <a *ngIf="hasChat | async" class="navbar-link chat">
                <div class="chat-icon">
                    <mmp5-mm-icons (click)="clearChatNots()" class="pointer" [ngStyle]="{'color': 'var(--navbar-subtitle-color)'}" [badge]="chatBadge" [icon]="['icon', 'icon-chat-logo']"></mmp5-mm-icons>
                    <mm-chat-tray (close)="showChatTray = !showChatTray" class="chat-tray" *ngIf="showChatTray"></mm-chat-tray>
                </div>
            </a>
            <a *ngIf="hasNotify" class="navbar-link notify">
                <div class="notify-icon">
                    <mmp5-mm-icons (click)="showNotify = !showNotify" class="pointer" [ngStyle]="{'color': 'var(--navbar-subtitle-color)'}" [badge]="unreadNotifys" [color]="'secondary'" [icon]="['icon', 'icon-notificacoes']"></mmp5-mm-icons>
                    <mm-notify-tray (close)="showNotify = !showNotify" class="notify-tray" *ngIf="showNotify"></mm-notify-tray>
                </div>
            </a>
            <!-- <a class="navbar-link btn-as-link" (click)="settings(navbar_item.settings_url)" *ngIf="hasPermissionToConfig">
                <mmp5-mm-icons [ngStyle]="{'color': 'var(--navbar-subtitle-color)'}" [badge]="2" [color]="'sixth'" [icon]="['icon','icon-configuracoes']"></mmp5-mm-icons>
            </a> -->
            <p class="nav-text">
                <ng-template #licenseUser>
                    <span class="nav-text"> {{loggedUser?.email}}</span>
                </ng-template>
            </p>
            <div class="profile-info" (mouseenter)="dropbox=true">

        <!--<img alt="foto de perfil do usuario" [routerLink]="'/hp/user-profile/me'" alt="avatar" class="img-fluid profile-avatar" [src]="mediaUrl ? (mediaUrl | authImage:'image':iTk:mdSrvc | async) : '../../assets/images/avatar.jpg'" />-->
                <img alt="foto de perfil do usuario" [routerLink]="'/hp/user-profile/me'" alt="avatar" class="img-fluid profile-avatar" [src]="profileBase64 ? profileBase64 : '../../assets/images/avatar.jpg'" />
                <div class="name">
                    <span [routerLink]="'/hp/user-profile/me'" class="profile-name">
                        <strong>Olá, {{loggedUser?.displayName}} <mmp5-mm-icons class="small" [icon]="['icon','icon-seta-2']"></mmp5-mm-icons></strong>
                    </span>
                    <p class="profile-nickname mb-0">{{loggedUser?.fullName}}</p>
                </div>

                <mmp-dropbox (mouseover)="dropbox=true" (mouseleave)="dropbox=false" (logout)="signout.emit(true)" [colors]="colors" [selectedId]="selectedColorId" (selected)="selectColor($event)" *ngIf="dropbox" [editProfile]="loggedUser?.id" class="dropbox-container"></mmp-dropbox>
            </div>
        </div>
    </div>
</nav>

<div [ngClass]="{'sidebar-nav':true, 'open': menu_toggle == true}"  *ngIf="(show == true) && loggedUser?.roles?.length > 0">

        <div class="fixed-sidebar-left sidebar--small">

            <div *ngIf="showHomepagesOnNavbar" class="menu-toggle homepage" (click)="redirect()">
                <div class="menu-icon">
                    <mmp5-mm-icons [icon]="['icon','icon-home']"></mmp5-mm-icons>
                </div>
            </div>

            <div class="menu-toggle" (click)="toggleMenu($event)">
                <div class="menu-icon">
                    <mmp5-mm-icons [icon]="['icon','icon-seta-1']"></mmp5-mm-icons>
                </div>
            </div>

            <div class="sidebar-list-container">
                <ng-scrollbar  [visibility]="'hover'">
                    <div class="main-icons" *ngFor="let item of menu_items">
                        <div *ngIf='canSee(item)'  [ngClass]="['sidebar-list','menu-icon','side-icon','main', isSelected(item.data), item?.contentMenu ? (contentMenuStatus ? 'selected' : '') : '' ]"  (click)="!item?.disabled && openItemMenu($event, item.data, item?.requireFunction, item?.sub_items, item?.url)">
                            <div
                                matTooltip="{{item.title}}"
                                aria-label="menu-icon"
                                [matTooltipClass]="'tooltip-default'"
                                [matTooltipPosition]="'right'"
                                [ngClass]="item.data == route_local ? 'active' : ''">
                                <mmp5-mm-icons [icon]="['icon','icon-'+item.icon ,'main-icons__icon']"></mmp5-mm-icons>
                            </div>
                        </div>
                    </div>
                </ng-scrollbar>

            </div>
        </div>


        <div class="fixed-sidebar-left sidebar--large">

                <div class="menu-toggle">
                    <div class="menu-icon " (click)="toggleMenu($event)">
                        <mmp5-mm-icons [icon]="['icon','icon-seta-4']"></mmp5-mm-icons>
                        <span>MMP5</span>
                    </div>
                </div>

                <div class="sidebar-list-container">
                    <ng-scrollbar  [visibility]="'hover'">
                        <div *ngFor="let item of menu_items">
                            <div *ngIf='canSee(item)' class="sidebar-list menu-icon">

                                <div class="item-a" *ngIf="item?.sub_items; else noSubItem">
                                    <a [ngClass]="[isSelected(item.data, item?.exact) == 'selected' ? 'active' : '', 'link-header']" (click)="toggleSubMenu($event, item.data, item?.requireFunction)">
                                        <mmp5-mm-icons [icon]="['icon','icon-'+item.icon]" class="side-icon"></mmp5-mm-icons>
                                        <span>{{item.title}}</span>
                                    </a>

                                    <div [@pageAnimations]="statemant" class="sub-menu" *ngIf="my_state === item.data">

                                        <div *ngFor="let sub_item of item.sub_items">

                                            <div *ngIf="sub_item?.items && canSee(sub_item); else noItem">
                                                <a [ngClass]="[isSelected(sub_item.data, sub_item?.exact) === 'selected' ? 'active':'']" (click)="toggleSubItem($event)">
                                                    <!-- <em [ngClass]="show_sub_item ? 'fa fa-angle-down' : 'fa fa-angle-right'"></em> -->
                                                    <mmp5-mm-icons [icon]="['fa-solid', show_sub_item ? 'fa-angle-down' : 'fa-angle-right']"></mmp5-mm-icons>
                                                    <span>{{sub_item.title}}</span>
                                                </a>
                                                <div class="sub-item" *ngIf="show_sub_item == true">
                                                    <div *ngFor="let item of sub_item.items">
                                                        <a *ngIf="canSee(item)" [routerLink]="[item.url]" routerLinkActive="active">
                                                            <!-- <em class="fa fa-circle"></em> -->
                                                            <mmp5-mm-icons [icon]="['fa-solid','fa-circle']"></mmp5-mm-icons>
                                                            <span>{{item.title}}</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            <ng-template #noItem>
                                                <a [ngClass]="[isSelected(sub_item.data, sub_item?.exact) === 'selected' ? 'active':'']" *ngIf="canSee(sub_item)" [routerLink]="[sub_item.url]">
                                                    <span>{{sub_item.title}}</span>
                                                </a>
                                            </ng-template>
                                        </div>
                                    </div>

                                </div>

                                <ng-template #noSubItem>
                                    <div class="item-b" *ngIf='canSee(item)'>
                                        <a [routerLink]="[]" [ngClass]="['link-header', isSelected(item.data, item?.exact) == 'selected' ? 'active' : '']" (click)="!item?.disabled && toRoute($event, item.data, item.url, item?.requireFunction)">
                                            <mmp5-mm-icons [icon]="['icon','icon-'+item.icon]" class="side-icon"></mmp5-mm-icons>
                                            <span>{{item.title}}</span>
                                        </a>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </ng-scrollbar>
                </div>
        </div>

</div>

<div [ngClass]="{'sidebar-nav fixed-sidebar-responsive':true, 'open': menu_toggle == true}" *ngIf="(show == true) && loggedUser?.roles?.length > 0">

            <div class="fixed-sidebar-left sidebar--small" id="sidebar-left-responsive">
                <div class="menu-toggle">
                    <div class="menu-icon responsive-icon" (click)="toggleMenu($event)">
                        <mmp5-mm-icons [icon]="['icon','icon-seta-1']"></mmp5-mm-icons>
                    </div>
                </div>
            </div>

            <div class="fixed-sidebar-left sidebar--large" id="sidebar-left-l-responsive">
                <div class="menu-toggle">
                        <div class="menu-icon" (click)="toggleMenu($event)">
                            <mmp5-mm-icons [icon]="['icon','icon-seta-4']"></mmp5-mm-icons><span>MMP5</span>
                        </div>
                </div>
                <div class="sidebar-list-container">
                        <ng-scrollbar  [visibility]="'hover'">
                            <div *ngFor="let item of menu_items">
                                    <div *ngIf='canSee(item)' class="sidebar-list menu-icon">

                                        <div  *ngIf="item?.sub_items; else noSubItem">

                                            <a [ngClass]="item.data == route_local ? 'active' : ''" (click)="toggleSubMenu($event, item.data, item?.requireFunction)">
                                                <mmp5-mm-icons [icon]="['icon','icon-'+item.icon]" class="side-icon"></mmp5-mm-icons>
                                                <span>{{item.title}}</span>
                                            </a>

                                            <div [@pageAnimations]="statemant" class="sub-menu" *ngIf="my_state === item.data">

                                                <div *ngFor="let sub_item of item.sub_items">

                                                    <div *ngIf="sub_item?.items && canSee(sub_item); else noItem">
                                                        <a (click)="toggleSubItem($event)">
                                                            <!-- <em [ngClass]="show_sub_item ? 'fa fa-angle-down' : 'fa fa-angle-right'"></em> -->
                                                            <mmp5-mm-icons [icon]="['fa-solid', show_sub_item ? 'fa-angle-down' : 'fa-angle-right']"></mmp5-mm-icons>
                                                            <span>{{sub_item.title}}</span>
                                                        </a>
                                                        <div class="sub-item" *ngIf="show_sub_item == true">
                                                            <div *ngFor="let item of sub_item.items">
                                                                <a *ngIf="canSee(item)" [routerLink]="[item.url]" routerLinkActive="active" >
                                                                    <!-- <em class="fa fa-circle"></em> -->
                                                                    <mmp5-mm-icons [icon]="['fa-solid','fa-circle']"></mmp5-mm-icons>
                                                                    <span>{{item.title}}</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <ng-template #noItem>
                                                        <a *ngIf="canSee(sub_item)" [routerLink]="[sub_item.url]" routerLinkActive="active">
                                                            <span>{{sub_item.title}}</span>
                                                        </a>
                                                    </ng-template>
                                                </div>
                                            </div>

                                        </div>

                                        <ng-template #noSubItem>
                                            <div *ngIf='canSee(item)'>
                                                <a [routerLink]="[]" (click)="toRoute($event, item.data, item.url, item?.requireFunction)">
                                                    <mmp5-mm-icons [icon]="['icon','icon-'+item.icon]" class="side-icon"></mmp5-mm-icons>
                                                    <span>{{item.title}}</span>
                                                </a>
                                            </div>
                                        </ng-template>
                                    </div>
                            </div>
                        </ng-scrollbar>
                    </div>
            </div>

</div>
