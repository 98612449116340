import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { Injectable, ApplicationRef, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { map, first, tap } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Store } from "@ngrx/store";

import * as moment from 'moment';
import { AlertController } from "mm-ui";

import { AppConfig } from "../../app.config";
import { LoggedUser } from "../../models/loggedUser.service";
import { AppComponent } from "../../app.component";
import * as UserActions from "../users/actions/users.actions"
import * as HomepageActions from "../cms/actions/homepageDefinition.actions"
import * as PendentActions from "../../actions/pendentItems.actions"
import { UserInterface } from "../../interfaces/userInterface";
import { Homepage } from "../../models/homepage";
import { PendentItem } from "../../models/pendentItem";
import { PendentItemsState } from "../../stores/pendentItems.state";
import { AuthConfigInterface } from "../../interfaces/AuthConfig";
import { OfficeConfig } from "../../interfaces/platformConfig";
import { ReCaptchaV3Service } from "ng-recaptcha-2";
import { NotifyService } from "../../services/notify.service";
import { ContentService } from "../cms/content/content.service";
import { Message, MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";

import { PlatformConfigService } from "../platform-config/platform-config.service";
import { EventsService } from "../../services/events.service";

declare var window: any;

@Injectable({
  providedIn: 'root'
})

export class LoginService implements OnInit {

  apiVersion = `v${AppConfig.data['apiVersion']}`;
  service = AppConfig.data['services']['auth'];
  endpoint = `${this.service}/${this.apiVersion}`
  git = AppConfig.data;
  endpointWebsocket = AppConfig.data['endpointNotifyWebsocket'];

  userService = AppConfig.data['services']['user'];
  timerToExpire: any;

  expiration$: Observable<any>;
  userHasInterected$ = new Subject();
  timerToRenew: any
  ws: WebSocket;
  googleClientId: string;
  singleExecutionSubscription: Subscription;

  //google auth
  public gapiSetup: boolean = false; // marks if the gapi library has been loaded
  public authInstance: gapi.auth2.GoogleAuth;

  clientId = localStorage.getItem('clientId');

  oktaAuth = {
    issuer: '',
    clientId: '',
    redirectUri: window.location.origin + '/login/callback'
  }


  constructor(private http: HttpClient,
    private userLogged: LoggedUser,
    private alertController: AlertController,
    private appRef: ApplicationRef,
    private storeU: Store<UserInterface>,
    private storeH: Store<Homepage>,
    private storeP: Store<PendentItemsState>,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private _notifyService: NotifyService,
    private user: LoggedUser,
    private _contentService: ContentService,
    private _messageService: MessageService,
    private _translateService: TranslateService,
    private platfConfigService: PlatformConfigService,
    private jwtHelper: JwtHelperService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.alertController.setViewContainerRef((this.appRef.components[0].instance as AppComponent).vcr)
  }

  listenToUserInterection() {
    this.userHasInterected$.subscribe((interaction: any) => this.saveUserInteration())
  }

  saveUserInteration(): void {
    let interaction = moment().unix().toString();
    localStorage.setItem('lastUserInteraction', interaction)
  }

  signin(data: { login: string, password: string, "g-recaptcha-response"?: string, clientId?: string, domain?: string }, type?: 'NORMAL' | 'AD'): Observable<any> {
    const credentials = {
      grant_type: 'password',
      login: data.login,
      password: data.password,
      clientId: data.clientId,
      domain: data.domain,
      "g-recaptcha-response": data["g-recaptcha-response"]
    }

    let url;

    if (type == 'AD') {
      url = `${this.endpoint}/oauth/ad`;
    } else {
      url = `${this.endpoint}/oauth/token`;
    }

    return this.http
      .post<any>(url, credentials, { headers: { ignoreInterceptorRedirect: 'true' } })
      .pipe(
        map(resp => {
          if (!resp.concurrentSession) {
            this.setAuthTimeout(resp);
            this.getPendentItems(resp);
          }
          return resp
        })
      )
  }

  signinOffice365(config: OfficeConfig) {
    return this.http.post<any>(`${this.endpoint}/oauth/ms/login`, config, { headers: { ignoreInterceptorRedirect: 'true' } })
  }

  signinOkta(config) {
    return this.http.post<any>(`${this.endpoint}/oauth/okta/login`, config, { headers: { ignoreInterceptorRedirect: 'true' } })

  }

  signinADFS(config: OfficeConfig) {
    return this.http.post<any>(`${this.endpoint}/oauth/adfs/login`, config, { headers: { ignoreInterceptorRedirect: 'true' } })
  }

  signinGoogle(token: any, clientId) {
    return this.http.post<any>(`${this.endpoint}/oauth/google`, { token, clientId }, { headers: { ignoreInterceptorRedirect: 'true' } })
  }
  signinGoogleOnLogin(token: any, clientId) {
    if (!clientId) {
      clientId = localStorage.getItem('clientId');
    }

    return this.http.post<any>(`${this.endpoint}/oauth/google`, { token, clientId }, { headers: { ignoreInterceptorRedirect: 'true' } }).pipe(
      map(resp => {
        if (!resp.concurrentSession) {
          this.setAuthTimeout(resp);
          this.getPendentItems(resp);
        }
        return resp
      })
    )
  }

  configGoogleClientID(id: string) {
    this.googleClientId = id;
  }

  loadGoogleAuth() {
    gapi.load('auth2', () => { })
  }

  async initGoogleAuth(): Promise<void> {
    //  Create a new Promise where the resolve
    // function is the callback passed to gapi.load
    const pload = new Promise((resolve) => {
      gapi.load('auth2', resolve);
    });

    // When the first promise resolves, it means we have gapi
    // loaded and that we can call gapi.init
    return pload.then(async () => {
      await gapi.auth2
        .init({ client_id: this.googleClientId })
        .then(auth => {
          this.gapiSetup = true;
          // this.authInstance = auth;
          this.authInstance.then(res => auth = res);
        });
    });
  }

  //matar esse metodo depois
  async authenticate(clientId): Promise<gapi.auth2.GoogleUser> {
    let replyUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
    localStorage.setItem('googleAuthClientId', clientId);
    // Initialize gapi if not done yet
    if (!this.gapiSetup) {
      await this.initGoogleAuth();
    }

    // Resolve or reject signin Promise
    return new Promise(async (resolve, reject) => {
      await this.authInstance.signIn(
        { ux_mode: 'redirect', redirect_uri: replyUrl }
      ).then(
        user => resolve(user),
        error => reject(error))
    });
  }

  testGoogleAccountId(googleClientId: string) {
    window.google?.accounts?.id?.initialize({
      client_id: googleClientId,
      callback: (resp) => {
        if (resp.credential) {
          this._messageService.add({ severity: 'success', summary: this._translateService.instant('platformConfig.auth.connectionSuccess') })
        } else {
          this._messageService.add({ severity: 'error', summary: this._translateService.instant('platformConfig.auth.connectionFailedContactSupp') })
        }
      },
      ux_mode: 'popup',
    });

    window.google?.accounts?.id?.prompt(() => { });
  }

  async googleAccountIdInitialize(callback) {
    let login_uri = `${window.location.protocol}//${window.location.host}/login/google/validate`

    window.google?.accounts?.id?.initialize({
      client_id: this.googleClientId,
      callback: (resp) => {
        this.signinGoogleOnLogin(resp.credential, this.clientId).subscribe(res => {
          this.validateLogin(res, this.googleClientId)
          callback()
        }, err => {
          this.validateError(err, 'loginGoogleValidate')
          callback()
        })
      },
      login_uri,
      ux_mode: 'popup',
      cancel_on_tap_outside: true
    });
  }

  googleAccountIdPrompt() {
    window.google?.accounts?.id?.prompt(notification => {
      const reason = notification.getNotDisplayedReason();

      if (reason) {

        // if (reason === 'suppressed_by_user') {
        //   document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        //   return window.google.accounts.id.prompt();
        // }

        let message: Message = {
          severity: 'error',
          summary: this._translateService.instant(`error.summary.loginGoogleValidate`),
          detail: this._translateService.instant(`error.detail.${reason}`),
        }

        this._messageService.add(message)

      }
    });
  }

  async checkIfUserAuthenticated(): Promise<boolean> {
    // Initialize gapi if not done yet
    if (!this.gapiSetup) {
      await this.initGoogleAuth();
    }

    return this.authInstance.isSignedIn.get();
  }


  connectMsOffice(config: OfficeConfig) {
    return this.http.post<any>(`${this.endpoint}/oauth/ms/connect`, config, { headers: { ignoreInterceptorRedirect: 'true' } })
  }

  connectOfficeLogin(config: OfficeConfig) {
    return this.http.post<any>(`${this.endpoint}/oauth/ms/connect`, config, { headers: { ignoreInterceptorRedirect: 'true' } })
      .pipe(
        map(resp => {
          this.setAuthTimeout(resp);
          this.getPendentItems(resp);
          return resp
        })
      )
  }

  connectOktaLogin(config) {
    return this.http.post<any>(`${this.endpoint}/oauth/okta/connect`, config, { headers: { ignoreInterceptorRedirect: 'true' } })
      .pipe(
        map(resp => {
          this.setAuthTimeout(resp);
          this.getPendentItems(resp);
          return resp
        })
      )
  }

  connectOktaLogin2(config) {
    return this.http.post<any>(`${this.endpoint}/oauth/okta/token`, config, { headers: { ignoreInterceptorRedirect: 'true' } })
      .pipe(
        map(resp => {
          this.setAuthTimeout(resp);
          this.getPendentItems(resp);
          return resp
        })
      )
  }

  connectADFSLogin(config: OfficeConfig) {
    return this.http.post<any>(`${this.endpoint}/oauth/adfs/connect`, config, { headers: { ignoreInterceptorRedirect: 'true' } })
      .pipe(
        map(resp => {
          this.setAuthTimeout(resp);
          this.getPendentItems(resp);
          return resp
        })
      )
  }

  connectADSSOLogin(clientId) {
    return this.http.post<any>(`${this.endpoint}/oauth/sso/connect`, { clientId }, { headers: { ignoreInterceptorRedirect: 'true' } })
      .pipe(
        map(resp => {
          this.setAuthTimeout(resp);
          this.getPendentItems(resp);
          return resp
        })
      )
  }

  confirmAuth(config, token) {
    return this.http.post(`${this.endpoint}/oauth/confirm`, config, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Confirmation': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    }).pipe(
      map((resp: any) => {
        this.setAuthTimeout(resp);
        this.getPendentItems(resp);
        return resp
      })
    )
  }

  // startNotify() {
  //     if(!isOnLogin()){
  //         this.connectWebsocket();
  //     }
  // }

  validateLogin(response, clientId, email?) {

    if (response.changePasswordRequired) {
      let type;

      if (response.firstAccess) {
        type = 'firstAccess';
      } else {
        type = 'periodic'
      }
      //persisit on localStorage only to use on set_password
      localStorage.setItem('accessTokenPassChange', response.accessToken);

      this.router.navigate(['/login', 'set_password', type], { queryParams: { clientId } });
    } else if (response.concurrentSession) {
      let url = `${window.location.origin}/login`;
      localStorage.setItem('multipleSession', JSON.stringify({ response, url, email }))
      this.router.navigate(['/login', 'concurrent-session'])
    } else {
      //persisit on localStorage
      this.setLocalStorage({ accessToken: response.accessToken });
      let loginType = localStorage.getItem('loginType');
      if (loginType) {
        localStorage.removeItem('loginType');
      }
      this.loginSucceed();
    }
  }

  decodeToken(token) {
    return this.jwtHelper.decodeToken(token);
  }

  loginSucceed() {
    let redirect: string = localStorage.getItem('redirectTo');
    localStorage.removeItem('redirectTo');
    localStorage.removeItem('alertList');

    this.connectEventSource();

    let user = this.user.getUser().user;
    this.user.updateUser(user);

    if (redirect && redirect.indexOf('login') == -1) {
      if (redirect.indexOf("?") > -1) {
        this.router.navigate([redirect.substring(0, redirect.indexOf("?"))]);
      } else {
        this.router.navigate([redirect]);
      }
    } else {
      let redirectHomepage = JSON.parse(localStorage.getItem('domain'));
      if (redirectHomepage && redirectHomepage.redirectHomePageId) {
        this.router.navigate(['/hp', redirectHomepage.redirectHomePageId])
        localStorage.removeItem('domain');
        localStorage.removeItem('authConfig');
      } else {
        this._contentService.ContentMenuList()
          .subscribe({
            next: (homes) => {

              if (homes.length > 0) {
                let firstHome = homes[0];
                this.router.navigate(['/hp', firstHome.homePageId]);
              } else {
                this.router.navigate(['/users', 'management', 'userfirstaccess']);
              }
            },
            error: () => this.router.navigate(['/users', 'management', 'userfirstaccess'])
          })
      }
    }
  }


  validateError(err, type) {
    //TODO: validate error
    this._messageService.add({ severity: 'error', summary: this._translateService.instant(`error.summary.${type}`), detail: this._translateService.instant(`error.detail.${type}`) })
  }

  showRecaptcha() {
    setTimeout(() => {

      let recaptcha_badge = document.querySelector('.grecaptcha-badge')

      if (recaptcha_badge) {


        if (!recaptcha_badge.classList.contains('show')) {

          recaptcha_badge.classList.add('show')
        }

      }
    }, 1500)
  }

  hideRecaptcha() {
    let recaptcha_badge = document.querySelector('.grecaptcha-badge')

    if (recaptcha_badge) {

      if (recaptcha_badge.classList.contains('show')) {

        recaptcha_badge.classList.remove('show')
      }
    }
  }

  hasRecaptcha() {
    return !AppConfig.data['recaptcha']['disabled'];
  }

  validateRecaptcha(form, action, callback: Function) {
    if (this.hasRecaptcha()) {
      if (this.singleExecutionSubscription) {
        console.log('sigle')
        this.singleExecutionSubscription.unsubscribe();
      }

      this.singleExecutionSubscription = this.googleRecaptchaV3(action)
        .subscribe((token) => {
          callback(form, token)
        }
        );

    } else {
      console.log('recaptcha disabled')
      callback(form)
    }
  }

  connectEventSource() {
    if (!this.eventsService.getConnectedStatus()) {
      this.eventsService.listenToEvents();
    }

    this.eventsService.listenToLogout().subscribe({
      next: () => {
        this.clearTimer();
        this.alertController.configure({
          error: 'destroySession',
          buttons: [
            {
              type: 'primary',
              text: 'Ok!',
              handler: () => {
                let auth = '/login';
                let clientid;
                if (this.userLogged.getDecodedToken()) {
                  let modeAuth = this.userLogged.getDecodedToken().modeAuth;
                  clientid = this.userLogged.getClientId()
                  localStorage.removeItem('accessToken')
                  if (modeAuth == 'ACTIVE_DIRECTORY') {
                    auth = '/login/ad'
                  } else if (modeAuth == 'GOOGLE') {
                    auth = '/login/google'
                  } else if (modeAuth == 'OFFICE_365') {
                    auth = '/login/office'
                  } else if (modeAuth == 'ADFS') {
                    auth = 'login/adfs'
                  } else if (modeAuth == 'OKTA') {
                    auth = 'login/okta'
                  } else if (modeAuth == 'X509') {
                    auth = 'login/all'
                  }
                  this.router.navigate([auth], { queryParams: { clientid, signedOut: 'true' } })
                }

              }
            }
          ]
        })
        this.alertController.open()
      }
    })
  }

  getGitHash() {
    return this.git.hash ? this.git.hash : null
  }

  getGitCount() {
    return this.git.count
  }

  getGitDate() {
    return this.git.date
  }

  passReset(data: { email: string, 'g-email-response'?: string, clientId: string, "g-recaptcha-response": string }): Observable<any> {
    return this.http.post<any>(`${this.userService}/${this.apiVersion}/users/email_reset`, data)
  }

  //V2
  googleRecapcha() {
    return this.http.get<{ googleRepachaSiteKey: string }>(`${this.endpoint}/recaptchasitekey`, { headers: { ignoreInterceptorRedirect: 'true' } })
  }

  g(secret, response) {
    return this.http.post<any>('https://www.google.com/recaptcha/api/siteverify', { secret, response })
  }

  async checkForCache() {
    let version = localStorage.getItem('version');
    if (version && this.git) {
      if (version < this.git.count) {
        if (window.caches) {
          await window.caches.keys().then(
            async function(names) {
              await Promise.all(names.map(name => caches.delete(name)))
            })
          localStorage.setItem('version', this.git.count);
          window.location.reload();
        }
      }
    } else {
      localStorage.setItem('version', this.git.count);
    }
  }

  googleRecaptchaV3(act) {
    return this.recaptchaV3Service.execute(act)
  }

  private showAlertPendent(status: PendentItem) {
    let route: Array<string>;

    status.createUser && (route = ['/users', 'management', 'create'])
    status.defineHomepage && (route = ['/cms', 'content', 'homepage-data'])

    this.alertController.configure({
      error: 'pendent',
      title: 'Uau!',
      flex: true,
      message: this._translateService.instant('signin.wereRetriever'),
      width: '544px',
      buttons: [
        {
          type: 'outline-dark',
          text: this._translateService.instant('signin.skip'),
          handler: () => {
            status.createUser && this.storeU.dispatch(new UserActions.ResetForm())
            status.defineHomepage && this.storeH.dispatch(new HomepageActions.ResetForm())
          }
        },
        {
          type: 'primary',
          text: this._translateService.instant('signin.view'),
          handler: () => {
            this.router.navigate(route)
          }
        }
      ]
    })
    this.alertController.open();
  }

  getPendentItems(resp: any) {
    this.storeP.select('pendentItems')
      .pipe(first())
      .subscribe((pending: PendentItem) => this.validatePendentItem(pending, resp))
  }

  validatePendentItem(pending: PendentItem, resp): void {
    let clientIdUser = this.jwtHelper.decodeToken(resp.accessToken).user &&
      this.jwtHelper.decodeToken(resp.accessToken).user.clientId ? this.jwtHelper.decodeToken(resp.accessToken).user.clientId : null;

    if (pending) {

      if (pending.defineHomepage) {
        if (clientIdUser == pending.clientId) {
          this.showAlertPendent(pending);
        } else {
          this.clearPendent();
        }
      } else if (pending.createUser) {
        this.showAlertPendent(pending);
      } else {
        this.clearPendent();
      }
    }
  }

  signout() {
    this.clearTimer();
    this.userLogged.updateUser(false);
    return this.http.post(`${this.endpoint}/logout`, {})
  }

  clearTimer() {
    clearTimeout(this.timerToExpire);
    clearTimeout(this.timerToRenew);
  }

  clearPendent() {
    this.storeP.dispatch(new PendentActions.ResetDefineHomepage());
  }

  dispatchPendent() {
    this.storeP.dispatch(new PendentActions.SetCreateUser())
  }

  changeFirstPass(pass) {
    let accessToken = localStorage.getItem('accessTokenPassChange');
    localStorage.removeItem('accessTokenPassChange');

    return this.http.post<any>(`${this.userService}/${this.apiVersion}/users/first/changePassword`, pass);
  }

  changeLoggedPass(pass) {
    let accessToken = localStorage.getItem('accessTokenPassChange');
    localStorage.removeItem('accessTokenPassChange');

    return this.http.post<any>(`${this.userService}/${this.apiVersion}/users/changePassword`, pass);
  }

  validatePassword(password) {
    return this.http.post<any>(`${this.userService}/${this.apiVersion}/users/validatePassword`, { password });
  }

  setAuthTimeout({ accessToken }) {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      let { exp, iat, user } = this.jwtHelper.decodeToken(accessToken)
      this.userLogged.updateUser(user);
      let now = moment().unix()
      let timeToExpire = (exp - now) * 1000;

      console.info(exp, now, timeToExpire)

      clearTimeout(this.timerToExpire)
      this.timerToExpire = setTimeout(() => {
        this.alertController.configure({
          title: 'Oops!',
          error: 'login',
          top: '19%',
          width: '400px',
          left: '57%',
          buttons: [
            {
              type: 'primary',
              text: 'Ok!',
              handler: () => {
                let auth = '/login';
                let clientid;
                if (this.userLogged.getDecodedToken()) {
                  let modeAuth = this.userLogged.getDecodedToken().modeAuth;
                  clientid = this.userLogged.getClientId()
                  localStorage.removeItem('accessToken')
                  if (modeAuth == 'ACTIVE_DIRECTORY') {
                    auth = '/login/ad'
                  } else if (modeAuth == 'GOOGLE') {
                    auth = '/login/google'
                  } else if (modeAuth == 'OFFICE_365') {
                    auth = '/login/office'
                  } else if (modeAuth == 'ADFS') {
                    auth = 'login/adfs'
                  }
                  this.clearTimer();
                  this.router.navigate([auth], { queryParams: { clientid } })
                }

              }
            }
          ]
        })
        this.alertController.open();
      }, timeToExpire);

      // 2 minutos
      let renewTime = 30000;

      this.setRenewTimeout(renewTime, accessToken)
    }
  }
  setRenewTimeout(renewTime, accessTokenUpdatedad) {
    clearTimeout(this.timerToRenew);
    this.timerToRenew = setTimeout(() => {
      let lastUserInteration: any = localStorage.getItem('lastUserInteraction')
      let accessToken = accessTokenUpdatedad;
      if (accessToken) {
        console.info({ 'interação': lastUserInteration > (moment().unix() - (renewTime / 1000)) })
        if (lastUserInteration > (moment().unix() - (renewTime / 1000))) {
          this.userLogged.updateUser(false);
          this.renewSession().subscribe(() => { }, (err) => this.setRenewTimeout(renewTime, accessToken));
        } else {
          this.setRenewTimeout(renewTime, accessToken)
        }
      }
    }, renewTime)
  }

  renewSession() {
    return this.http.post<any>(`${this.endpoint}/auth/refresh/token`, {}).pipe(tap((resp) => {
      console.info('sessão renovada', resp)
      this.setAuthTimeout(resp)
    }))
  }

  setLocalStorage({ accessToken }: any) {
    let decode = this.jwtHelper.decodeToken(accessToken);
    localStorage.setItem('accessToken', accessToken)
  }

  userHasInterected() {
    this.userHasInterected$.next(moment().unix());
  }

  getDomains(clientId) {
    return this.http.get(`${this.endpoint}/oauth/ad/domains/${clientId}`)
  }

  getOfficeConfigs(clientId) {
    return this.http.get(`${this.endpoint}/oauth/ms/domains/${clientId}`, { headers: { ignoreInterceptorRedirect: 'true' } })
  }

  changePass(data: { resetPasswordToken: string, password: string, passwordConfirmation: string, "g-recaptcha-response": string }, define?: boolean) {
    if (define) {
      return this.http.post<any>(`${this.userService}/${this.apiVersion}/users/set_password`, data)
    } else {
      return this.http.post<any>(`${this.userService}/${this.apiVersion}/users/reset_password`, data)
    }
  }

  getAuthConfigByClient(id: string): Observable<AuthConfigInterface> {
    let headers = new HttpHeaders().append('accept', 'application/json').append('content-type', 'application/json').append('ignoreInterceptorRedirect', 'true')

    return this.http.get<AuthConfigInterface>(`${this.service}/${this.apiVersion}/auth_config/client/${id}`, { headers })
  }
  resetPasswordByUserData(userData, clientId) {
    return this.http.post<any>(`${this.userService}/${this.apiVersion}/users/confirmUserData/${clientId}`, userData);
  }

  oktaCredentials(clientId) {
    return this.http.get(`${this.endpoint}/oauth/okta/credentials/${clientId}`, { headers: { ignoreInterceptorRedirect: 'true' } })
  }
}
