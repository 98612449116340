import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { ChatService } from "../../../components/chat/chat.service";
import { first, distinctUntilChanged, filter, map } from "rxjs/operators";
import { Subscription } from "rxjs";
import { User } from "../../../models/User.service";
import { usersData } from "../../../resolvers/usersData";
import { ChatConversationsInterface, chatMessages } from "../../../models/chat-interface";
import { Router, ActivatedRoute } from "@angular/router";
import { EventsService } from "../../../services/events.service";
import { CommonModule } from "@angular/common";
import { ChatUsersImagesComponent } from "../chat-users-images/chat-users-images.component";
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ChatUsersImagesComponent,
  ],
  selector: 'mm-chat-tray-data',
  templateUrl: './chat-tray-data.component.html',
  styleUrls: ['./chat-tray-data.component.scss']
})
export class ChatTrayDataComponent implements OnInit, OnDestroy {

  @Input('data-rooms') rooms: any;
  // rooms
  @Input('data-fullscream') fullscream: any;
  @Output('chat-conversation-data') conversationData: EventEmitter<any> = new EventEmitter();
  selected: boolean;
  lastMessage: string = ''
  loggedUserId: string

  page: number = 1;
  hasNextPage: boolean

  roomsSub: Subscription;
  sendReceveidMessageSub: Subscription;
  searchRoomsReturnedSub: Subscription;

  constructor(
    private chatService: ChatService,
    private eventsService: EventsService,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }
  ngOnInit(): void {

    this.chatService.loggedUserReturned
      .subscribe(user => {
        this.loggedUserId = user.id;
      })

    this.getSearchedConversations();
    this.getAllRooms();
  }

  gtRooms(page) {

    // this.chatService.getRooms(page)
    //   .subscribe(async (rooms:any) => {

    //     if(rooms && rooms.docs){
    //       let roomsPaginated = rooms.docs
    //       let verifingUserRooms = roomsPaginated.map(room => room.users).map(roomUser => roomUser.some(u => u.userId == this.loggedUserId))
    //       let userRooms = roomsPaginated.filter((room,index) => verifingUserRooms[index] == true)

    //         if(this.page == 1)
    //           this.rooms = userRooms;
    //         else{
    //           this.rooms = [...this.rooms, ...userRooms]
    //         }

    //         this.page = rooms.nextPage;

    //         console.log(this.rooms)


    //       await this.chatService.allConversations$.next(this.rooms);


    //     }
    //   });
  }

  getAllRooms() {
    // this.rooms = null
    // this.roomsSub = this.chatService.conversationsReturned
    // .subscribe(async (rooms:ChatConversationsInterface) => {
    //   let allrooms = await rooms;
    //   this.rooms = allrooms;

    this.route.params.subscribe(conv_id => {
      if (conv_id && conv_id.id)
        this.selected = conv_id.id
    })
    // });

  }

  getSearchedConversations() {
    this.searchRoomsReturnedSub = this.chatService.searchRoomsReturned
      .subscribe(async (rooms: ChatConversationsInterface) => {
        this.rooms = await rooms;
      })
  }


  getItem(roomIndex) {

    this.selected = this.rooms[roomIndex]._id

    if (this.fullscream) {
      this.router.navigate(['chats', 'all-conversations', this.rooms[roomIndex]._id])
    } else {
      window.open(`/chats/all-conversations/${this.rooms[roomIndex]._id}`, '_blank')
    }
  }

  ngOnDestroy() {
    if (this.roomsSub)
      this.roomsSub.unsubscribe();

    if (this.sendReceveidMessageSub)
      this.sendReceveidMessageSub.unsubscribe();

    if (this.searchRoomsReturnedSub)
      this.searchRoomsReturnedSub.unsubscribe();
  }


}

