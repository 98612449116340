import { Injectable } from "@angular/core";
import { AppConfig } from "../../../../app.config";
import { BehaviorSubject, of, Observable } from "rxjs";
import { Page, versionDetail, CompareVersion, PageVersionClass } from "../../../../models/page";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import * as PageActions from "../../../../actions/page.actions"
import { switchMap, first, map, tap } from "rxjs/operators";
import { Homepage } from "../../../../models/homepage";
import { Template } from "projects/f-cms/src/app/interfaces/template";
import { HomepageDefinitionState } from "../../cms.state";
import { Flowsurvey } from "projects/f-cms/src/app/interfaces/flowsurvey";

@Injectable()
export class CreatePageService {

    apiVersion = `v${AppConfig.data['apiVersion']}`;
    service = AppConfig.data['services']['cms'];
    endpoint = `${this.service}/${this.apiVersion}`

    private page$: BehaviorSubject<Page> = new BehaviorSubject({} as Page)

    constructor(
        private http: HttpClient,
        public store: Store<HomepageDefinitionState>
    ){}

    emptyPage: Page = {
        id: null,
        pageFather: {
            id: null
        },
        title: null,
        description: null,
        editNote:null,
        creationDate: null,
        expirationDate: null,
        base64CoverImage: null,
        createdById: null,
        createdByIp: null,
        expired: null,
        homePage: {} as Homepage,
        lastModifiedById: null,
        lastModifiedByIp: null,
        lastModifiedDate: null,
        homePageParent: null,
        menuTitle: null,
        nameCoverImage: null,
        published: null,
        publishingDate: null,
        pageVersion: {} as PageVersionClass,
        topViewMenu: null,
        order: 1,
        pages: [],
        template: {id: ""} as Template,
        inheritTemplate: 'N',
        observation: null
    }

    getPage(id?: string, menuId?: string){
        if(id){
            return this.http.get<Page>(`${this.endpoint}/pages/${menuId ? menuId+'/': ''}${id}`).pipe(tap(page => this.page$.next(page)))
        } else {
            return of(this.emptyPage)
        }
    }

    getPageView(id: string){
        return this.http.get<Page>(`${this.endpoint}/pages/${id}/view`)
    }

    updatePageObg(page: Page){
        this.page$.next({...this.page$.value, ...page})
    }

    getPageObj(): Observable<Page>{
        return this.page$.asObservable()
    }

    getPageVersions(pageId: string){
        return this.http.get<versionDetail[]>(`${this.endpoint}/pages/${pageId}/versions`)
    }
    getViewPageVersions(pageId: string){
        return this.http.get<versionDetail[]>(`${this.endpoint}/pages/${pageId}/feature/versions`)
    }

    comparePages(pageId: string, pageTwo: string){
        return this.http.get<CompareVersion>(`${this.endpoint}/pages/versions/${pageId}/${pageTwo}`)
    }

    getPageVersionById(versionId: string): Observable<Page>{
        return this.http.get<Page>(`${this.endpoint}/pages/versions/${versionId}`).pipe(tap(page => this.page$.next(page)))
    }

    savePage(page: Page, noVersioning?:boolean, parentId?: string): Observable<Page>{
        let params;

        if(noVersioning){
            params = {noVersioning}
        }

        if (page.id){

            return this.http.put<Page>(`${this.endpoint}/pages/${page.id}`, {...page, pages: undefined} as Page, {params})
                            .pipe(
                                // tap((page) => {
                                //     this.store.dispatch(new PageActions.ResetPage())
                                //     this.getPage(page.id).subscribe(() => {})
                                //     // this.page$.next(page)
                                // })
                                tap(() => this.store.dispatch(new PageActions.ResetPage())),
                                switchMap(() => this.getPage(page.id, parentId))
                            )
        } else {
            return this.http.post<Page>(`${this.endpoint}/pages/create/page`, page)
        }
    }

    deletePage(menuId:string,pageId: string, params){
        return this.http.delete(`${this.endpoint}/pages/${menuId}/${pageId}`, {params})
    }

    hasImportations(pageId){
        return this.http.get<any>(`${this.endpoint}/page/has/import/${pageId}`)
    }

    deleteImportationPage(id) {
        return this.http.delete(`${this.endpoint}/page/import/${id}`)
    }

    removeAllImports(pageId){
        return this.http.delete<any>(`${this.endpoint}/page/imports/${pageId}`)
    }

    pageImportations(pageId) {
        return this.http.get<any>(`${this.endpoint}/page/import/${pageId}`)
    }

    LoadPage(id: string){
        return this.store.dispatch(new PageActions.LoadPage({id}))
    }

    getFlowsurveyList(){
        return this.http.get<Flowsurvey[]>(`${this.endpoint}/flowsurvey/`)
    }

    getTags(tag: string){
        return this.http.put(`${this.endpoint}/pages/tags/search`, {tag})
    }

    getFlowsurveyListByNameOrId(id: string){
      return this.http.get<Flowsurvey>(`${this.endpoint}/flowsurvey/search/${id}`)
    }
}
