import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { DefineUserInterface } from "../../interfaces/defineUserInterface";
import { UserInterfaceResponse } from "../../interfaces/userInterfaceResponse";
import { AppConfig } from "../../app.config";
import { UserInterface, grouperUserInterface, UserLoadHistory, UserLoadWebservice } from "../../interfaces/userInterface";
import { FieldUserInfo } from "../../interfaces/fieldUserInfo";
import { HistoryUserInterface } from "../../interfaces/historyUserInterface";
import { BehaviorSubject, Observable } from "rxjs";
import { InputInterface } from "../../interfaces/inputInterface";

@Injectable({ providedIn: 'root' })
export class UsersService {

  apiVersion = `v${AppConfig.data['apiVersion']}`;
  service = AppConfig.data['services']['user'];
  auth = AppConfig.data['services']['auth'];
  serviceReport = AppConfig.data['services']['report'];
  endpoint = `${this.service}/${this.apiVersion}`
  endpointReport = `${this.serviceReport}/${this.apiVersion}`
  endpointDownload = `${AppConfig.data['services']['gateway']}/download/user/${this.apiVersion}`
  endpointReportDownload = `${AppConfig.data['services']['gateway']}/download/report/${this.apiVersion}`
  endpointAuth = `${this.auth}/${this.apiVersion}`

  messageChange = new BehaviorSubject<{}>(null);
  newMessage = this.messageChange.asObservable();

  historyParams = new BehaviorSubject<string>(null);
  getHistoryParams = this.historyParams.asObservable();

  constructor(private http: HttpClient) {

  }

  getUsers(data?: any) {
    if (data) {
      return this.http.get<UserInterfaceResponse>(`${this.endpoint}/users`, { params: data });
    } else {
      data = {
        size: 20,
        page: 0,
        sort: 'fullName.original,asc'
      };
      return this.http.get<UserInterfaceResponse>(`${this.endpoint}/users`, { params: data });
    }
  }

  createUser(data, id?: string) {
    if (id) {
      return this.http.post<UserInterface>(`${this.endpoint}/users/${id}`, data);
    } else {
      return this.http.post<UserInterface>(`${this.endpoint}/users`, data);
    }
  }

  getUserByIdMini(id: string) {
    return this.http.get(`${this.endpoint}/users/${id}/mini`)
  }

  updateProfileInfo(user: UserInterface) {
    return this.http.put<UserInterface>(`${this.endpoint}/users/profile`, user);
  }

  searchUserView(queryTerm, page, size) {
    return this.http.post<UserInterfaceResponse>(`${this.endpoint}/users/search/main`, { queryTerm, page, size })
  }

  searchUsers(data: {
    query?: string,
    showOff?: boolean,
    showNotChargeable?: boolean,
    showNotViewUser?: boolean,
    size?: string | number,
    page?: string | number,
    sort?: string
  }) {
    /**
    * Returns the list of users. the Loggeduser needs to have UP ou UP_PLUS permission. for view with loggedUser without UP use searchUserView.
    *
    * @param data - The user search query
    * @returns The list of Users
    *
    * @beta
    */
    return this.http.get<UserInterfaceResponse>(`${this.endpoint}/users/search`, { params: data as any });
  }

  restoreVersion(userId: string, userChangeId: string) {
    return this.http.post<any>(`${this.endpoint}/users/${userId}/restore/${userChangeId}`, {});
  }

  getProfileData() {
    return this.http.get<DefineUserInterface>(`${this.endpoint}/users/fields`);
  }

  sendProfileData(data) {
    return this.http.post<DefineUserInterface>(`${this.endpoint}/users/fields`, data);
  }
  getUser(id): Observable<UserInterface> {
    return this.http.get<UserInterface>(`${this.endpoint}/users/${id}`);
  }

  getUserProfile(id): Observable<UserInterface> {
    return this.http.get<UserInterface>(`${this.endpoint}/users/profile/${id}`);
  }

  getLoggedUserData(): Observable<UserInterface> {
    return this.http.get<UserInterface>(`${this.endpoint}/users/me`);
  }

  getSearchFields() {
    return this.http.get<Array<string>>(`${this.endpoint}/users/search-fields`);
  }

  getAvailableFields() {
    return this.http.get<DefineUserInterface>(`${this.endpoint}/users/fields-available`)
  }

  // user definition without user-def role
  getFieldsAvailable(): Observable<InputInterface[]> {
    return this.http.get<InputInterface[]>(`${this.endpoint}/users/available-fields`)
  }

  getHistory(param) {
    return this.http.get<HistoryUserInterface>(`${this.endpoint}/users/history`, { params: param });
  }

  getFieldsInfo() {
    return this.http.get<FieldUserInfo>(`${this.endpoint}/users/search-user-info`);
  }

  getSearchFieldsName() {
    return this.http.get<Array<string>>(`${this.endpoint}/users/search-fields-name`);
  }

  sendHistoryParams(params: string) {
    this.historyParams.next(params);
  }

  getGrouperFieldValues(grouperField: string, grouperFields: grouperUserInterface) {
    return this.http.get<string[]>(`${this.endpoint}/users/grouperField/${grouperField}`, { params: grouperFields as any })
  }

  getAllGrouperFieldValues(grouperField: string) {
    return this.http.get<string[]>(`${this.endpoint}/users/grouperField/${grouperField}/all`);
  }

  getUserLoadFileConfig() {
    return this.http.get<any>(`${this.endpoint}/config/file/userImports/`)
  }

  getUserLoadConfig() {
    return this.http.get<any>(`${this.endpoint}/config/userImports/`)
  }

  setUserLoadConfig(config) {
    return this.http.put<any>(`${this.endpoint}/config/userImports/`, config)
  }

  setUserLoadConfigImportFields(fields: any) {
    return this.http.put(`${this.endpoint}/config/userImports/fields`, fields)
  }

  testAdConfig(config: any) {
    return this.http.post(`${this.endpoint}/ad/userImports/valid`, config)
  }

  setUserLoadFileConfig(config) {
    return this.http.put<any>(`${this.endpoint}/config/file/userImports/`, config)
  }

  getFileModelDownload() {
    return this.http.get(`${this.endpointDownload}/userImports/`, { observe: 'response', responseType: 'blob' })
    // return saveAs(`${this.endpoint}/download/userImports/`, '.csv');
  }
  getFileDownloadUrl() {
    return `${this.endpointDownload}/file/userImports/`
  }

  saveUserLoadAdConfig(config) {
    return this.http.put(`${this.endpoint}/ad/userImports`, config)
  }

  getUserLoadAdConfig(id?: string) {
    let usingId = id ? `/${id}` : '';
    return this.http.get(`${this.endpoint}/ad/userImports${usingId}`)
  }

  testLoadFile(obj) {
    return this.http.post<any>(`${this.endpoint}/file/userImports/test/file/`, obj);
  }

  testADCharge(obj) {
    return this.http.post<any>(`${this.endpoint}/ad/userImports/import/valid`, obj)
  }

  execLoadFile(obj) {
    return this.http.post<any>(`${this.endpoint}/file/userImports/file/`, obj);
  }

  execADCharge(obj) {
    return this.http.post<any>(`${this.endpoint}/ad/userImports/import/build`, obj);
  }

  getUserLoadHistory(page: any = 0) {
    return this.http.get<UserLoadHistory>(`${this.endpoint}/file/userImports/file/history/`, { params: { page } });
  }

  getUserADChargeHistory(page: any = 0) {
    return this.http.get<any>(`${this.endpoint}/ad/userImports/history/`, { params: { page } });
  }

  getUserLoadWebserviceHistory() {
    return this.http.get<UserLoadWebservice>(`${this.endpoint}/wsimport/history`)
  }

  removeADConfig(id: string) {
    return this.http.delete<any>(`${this.endpoint}/ad/userImports/${id}`)
  }

  downloadLGPDProfileData() {
    return this.http.get(`${this.endpointDownload}/lgpd/profile`, { observe: 'response', responseType: 'blob' })
  }

  downloadLGPDPageData() {
    return this.http.get(`${this.endpointReportDownload}/lgpd/page`, { observe: 'response', responseType: 'blob' })
  }

  downloadLGPDActivity() {
    return this.http.get(`${this.endpointReportDownload}/lgpd/activity`, { observe: 'response', responseType: 'blob' })
  }

  downloadHistoryUserLoadFile(id: string) {
    return this.http.get(`${this.endpointDownload}/userImports/file/content/${id}`, { observe: 'response', responseType: 'blob' })
  }
  getUserGroups(id, params) {
    return this.http.get<any>(`${this.endpointAuth}/users/groups/${id}`, { params })
  }

  getUsersMentionsComments(query: string) {
    return this.http.get<any>(`${this.endpoint}/users/search/autosuggest?query=${query}`)
  }
  getWSIpRangeList() {
    return this.http.get<any>(`${this.endpointAuth}/ipranges`)
  }

  saveWSIpRangeList(list) {
    console.log(typeof list)
    return this.http.post<any>(`${this.endpointAuth}/ipranges`, list)
  }

  deleteWSIpRangeList() {
    return this.http.delete<any>(`${this.endpointAuth}/ipranges`)
  }

  getAzureAdConfig() {
    return this.http.get<any>(`${this.endpoint}/azureAdConfig`);
  }

  saveAzureAdConfig(config) {
    return this.http.put<any>(`${this.endpoint}/azureAdConfig`, config);
  }

  deleteAzureAdConfig(id) {
    return this.http.delete<any>(`${this.endpoint}/azureAdConfig/${id}`);
  }

  testAzureAdConfig(id) {
    return this.http.post<any>(`${this.endpoint}/azureAdImport/userImports/test/${id}`, {});
  }

  runAzureAdImport(configId) {
    return this.http.post<any>(`${this.endpoint}/azureAdImport/userImports/import/${configId}`, {});
  }

  getAzureAdHistory(page = 0, size = 20) {
    return this.http.get<any>(`${this.endpoint}/azureAdHistory/all`, { params: { page, size } });
  }

  getLGPDProfileDataEmail(to: string) {
    return this.http.post<any>(`${this.endpoint}/email/lgpd/profile`, { to });
  }

  getLGPDPageDataEmail(to: string) {
    return this.http.post<any>(`${this.endpointReport}/email/lgpd/page`, { to });
  }

  getLGPDActivityEmail(to: string) {
    return this.http.post<any>(`${this.endpointReport}/email/lgpd/activity`, { to });
  }

  getGoogleAdConfig() {
    return this.http.get<any>(`${this.endpoint}/config/googleImportConfigs`);
  }
  saveGoogleAdConfig(config) {
    return this.http.put<any>(`${this.endpoint}/config/googleImportConfigs`, config);
  }
  runGoogleAdImport(configId) {
    return this.http.post<any>(`${this.endpoint}/googleImport/userImports/import/${configId}`, {});
  }
  deleteGoogleAdConfig(id) {
    return this.http.delete<any>(`${this.endpoint}/config/googleImportConfigs/${id}`);
  }
  testGoogleAdConfig(id) {
    return this.http.post<any>(`${this.endpoint}/googleImport/userImports/test/${id}`, {});
  }
  getGoogleAdHistory(page = 0, size = 20) {
    return this.http.get<any>(`${this.endpoint}/googleImport/userImports/history`, { params: { page, size } });
  }
}
