import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { AppConfig } from "../../app.config";
import { LoggedUser } from "../../models/loggedUser.service";
import { BehaviorSubject, Subscription, Subject } from "rxjs";
import { MessageService } from "primeng/api";
import { map, first } from "rxjs/operators";
import { ElementSchemaRegistry } from "@angular/compiler";
import { UserInterface } from "projects/mm-ui/src/lib/navbar/user-interface";

@Injectable({ providedIn: 'root' })
export class ChatService {

  private apiVersion = `v${AppConfig.data['apiVersion']}`;
  private service = AppConfig.data['services']['chat'];
  private authService = AppConfig.data['services']['auth'];
  private endpoint = `${this.service}/${this.apiVersion}`;
  private authEndpoint = `${this.authService}/${this.apiVersion}`;

  loggedUser$ = new BehaviorSubject<UserInterface>(null);
  loggedUserReturned = this.loggedUser$.asObservable()
  allConversations$ = new BehaviorSubject<any>(null);
  conversationsReturned = this.allConversations$.asObservable();
  conversation$ = new BehaviorSubject<any>(null);
  conversationReturned = this.conversation$.asObservable();
  searchRooms$ = new BehaviorSubject<any>(null);
  searchRoomsReturned = this.searchRooms$.asObservable();
  listOfMessages$ = new BehaviorSubject<any>(null);
  listOfMessagesReturned = this.listOfMessages$.asObservable();
  selectedRoom$ = new BehaviorSubject<boolean>(false);
  hasSelectedRoom = this.selectedRoom$.asObservable();
  renamedConversation: Subject<any> = new Subject<any>();
  returnedAllConversationsList: Subject<any>;
  fileMessages: Subject<any>;

  media$ = new BehaviorSubject<any>(null)
  mediaReturned = this.media$.asObservable();

  roomsSub: Subscription;
  returnedAllConversationsListSub: Subscription

  constructor(
    private _http: HttpClient,
    private _user: LoggedUser,
    private messageService: MessageService,
  ) { }

  getRooms(nextPage?) {
    return this._http.get(`${this.endpoint}/rooms`, { params: { paginate: "true", size: "10", nextPage } })
  }


  getRoom(id) {
    return this._http.get(`${this.endpoint}/rooms/${id}`)
  }

  createRoom(name, users) {
    return this._http.post(`${this.endpoint}/rooms`, { name, users })
  }

  changeRoomName(id, name) {
    return this._http.put(`${this.endpoint}/rooms/${id}/name`, { name })
  }

  searchRooms(text) {
    return this._http.post(`${this.endpoint}/rooms/search`, { text })
  }


  addUserToRoom(id, userId) {
    return this._http.put(`${this.endpoint}/rooms/${id}/user/${userId}`, null)
  }

  deleteUserToRoom(id, userId) {
    return this._http.delete(`${this.endpoint}/rooms/${id}/user/${userId}`, null)
  }

  createMessage(roomId, text, replyTo?: string) {
    return this._http.post(`${this.endpoint}/communication`, { roomId, text, replyTo })
  }

  listMessages(roomId, nextPage, size) {
    return this._http.post(`${this.endpoint}/communication/messages`, { roomId, nextPage, size })
  }

  searchUser(name) {
    return this._http.post(`${this.endpoint}/communication/messages/user`, { name })

  }

  searchWithActiveUsers(params) {
    return this._http.get(`${this.endpoint}/chats/users/search`, { params: { query: params.query, page: params.page, size: params.size } })

  }

  sendReceveidMessage(roomId, value) {
    return this._http.put(`${this.endpoint}/rooms/${roomId}/receivedMessage/${value}`, null)
  }

  getUnreadedMessages() {
    return this._http.get(`${this.endpoint}/rooms/unreaded/messages`)
  }

  setMessage(severity: string, summary: string, detail: string): void {

    return this.messageService.add({ severity, summary, detail })
  }

  getAllRooms(user, nextPage = 1) {
    this.allConversations$.next(null);
    this.roomsSub = this.getRooms(nextPage)
      .subscribe(async (rooms: any) => {
        if (rooms && rooms.docs) {
          let roomsPaginated = rooms.docs
          let verifingUserRooms = roomsPaginated.map(room => room.users).map(roomUser => roomUser.some(u => u.userId == user.id))
          let userRooms = roomsPaginated.filter((room, index) => verifingUserRooms[index] == true)
          await this.allConversations$.next(userRooms)

        }
      });
  }

  getConversationMessages(id, page, size) {

    this.listMessages(id, page, size)
      .pipe(first())
      .subscribe(async res => {
        console.log({ p: res })
        await this.listOfMessages$.next(res);
      }, err => console.log(err))
  }

  leaveConversation(roomId, userId) {
    return this._http.delete(`${this.endpoint}/rooms/${roomId}/user/${userId}`)
  }

  receivingFileMessage(fileMedia, contentType) {
    return this._http.get(`${this.endpoint}/media`, { params: { fileMedia, contentType } })
  }

  renameWsConversation(roomId, name) {
    let message = {
      roomId,
      name
    }

    this._http.post(`${this.endpoint}/communication/changeGroup/${roomId}`, message).subscribe(() => {
      this.renamedConversation.next(message)
    })
  }

  addingUserToConversation(roomId, userId) {
    let message = {
      roomId,
      userId
    }

    return this._http.put(`${this.endpoint}/rooms/${roomId}/user/${userId}`, message);
  }

  sendFileMessage(roomId, originalFilename, contentType, base64) {
    const file = this.dataURIToBlob(base64);
    const formData = new FormData();
    formData.append('file', file, originalFilename);

    return this._http.post(`${this.endpoint}/communication/messages/upload/${roomId}`, formData, {
      headers: {
        'multi-part-form': 'true'
      }
    })
  }

  private dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  validateAccsess() {
    return this._http.get(`${this.endpoint}/communication/validate-access`)
  }

  ngOnDestroy() {

    if (this.roomsSub)
      this.roomsSub.unsubscribe();

    if (this.returnedAllConversationsListSub)
      this.returnedAllConversationsListSub.unsubscribe();
  }
}
