import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {

  appName: string;
  urlIcon: string;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { mobileType: 'ios' | 'android', promptEvent?: any },
    private bottomSheetRef: MatBottomSheetRef<PromptComponent>
  ) {}

  public installPwa(): void {
    this.data.promptEvent.prompt();
    this.close();
  }

  public close() {
    this.bottomSheetRef.dismiss();
  }

  ngOnInit() {

    const cms = localStorage.getItem('cms');
    let parsed = JSON.parse(cms);
    if (parsed.homepageDefinition !== undefined) {
      this.appName = (parsed.homepageDefinition.appName) ? parsed.homepageDefinition.appName : "MMP5"
      this.urlIcon = (parsed.homepageDefinition.urlIcon) ? parsed.homepageDefinition.urlIcon : "/assets/icons/icon-144x144.png"
    } else {
      this.appName = "MMP5"
      this.urlIcon = "/assets/icons/icon-144x144.png"
    }

  }

}
