import { Component, Output, EventEmitter, OnInit, ViewEncapsulation, Input, SimpleChanges, OnDestroy } from "@angular/core";
import { ChatService } from "../../components/chat/chat.service";
import { Subscription } from "rxjs";
import { LoggedUser } from "../../models/loggedUser.service";
import { Router, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { LoadingSpinnerModule, MMIconsModule, TooltipModule } from "mm-ui";
import { NgScrollbarModule } from "ngx-scrollbar";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ChatTrayDataComponent } from "./chat-tray-data/chat-tray-data.component";
import { MediaService } from "../../components/media/media.service";

@Component({
    standalone:true,
    selector: 'mm-chat-tray',
    imports: [
      CommonModule,
      TranslateModule,
      RouterModule,
      NgScrollbarModule,
      LoadingSpinnerModule,
      InfiniteScrollModule,
      ChatTrayDataComponent
    ],
    templateUrl: './chat-tray.component.html',
    styleUrls: ['./chat-tray.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
export class ChatTrayComponent implements OnInit, OnDestroy {

  @Output('close') close = new EventEmitter();
  @Output('conversation-data') conversationData:EventEmitter<any> = new EventEmitter();
  @Input('hide-header') hideHeader:boolean;
  @Input('hide-footer') hideFooter:boolean;
  @Input('all-rooms') rooms;
  @Input('fullscream') fulscream:boolean = false;

  // rooms:any[] = [];
  roomsSub:Subscription;
  loggedUser;
  page:number = 1;
  hasNextPage:boolean

  constructor(
    private chatService: ChatService,
    private router: Router,
    private _user: LoggedUser
  ){}


  ngOnInit(): void {

    this.loggedUser = this._user.getUser() && this._user.getUser().user;
    let first = true;
    let clientid = this._user.getClientId();
    let modeAuth = this._user.getDecodedToken().modeAuth;
    let auth = '';

    if(modeAuth == 'ACTIVE_DIRECTORY'){
      auth = 'ad'
    } else if (modeAuth == 'GOOGLE'){
      auth = 'google'
    } else if (modeAuth == 'OFFICE_365'){
      auth = 'office'
    } else if (modeAuth == 'ADFS'){
      auth = 'adfs';
    } else if (modeAuth == 'MANUAL'){
      auth = "manual"
    }else if (modeAuth == 'X509'){
      auth = "all"
    }

    if(first){
      setInterval(() => {
        let user = this._user.getUser() && this._user.getUser().user;

        if(!user){

            localStorage.removeItem('accessToken');
            if(clientid){
              this.router.navigate([`/login${auth ? `/${auth}` : ''}`], {queryParams: {clientid, signedOut: 'true'}});
            }else{
              this.router.navigate([`/login${auth ? `/${auth}` : ''}`], {queryParams: {signedOut: 'true'}});
            }
            first = false
        }
      }, 2000);
    }

    this.chatService.loggedUser$.next(this.loggedUser);

    this.gtRooms(this.page)

      if(!this.rooms)
        this.getAllRooms();

  }

  gtRooms(page){

    this.chatService.getRooms(page)
      .subscribe(async (rooms:any) => {

        if(rooms && rooms.docs){
          let roomsPaginated = rooms.docs
          let verifingUserRooms = roomsPaginated.map(room => room.users).map(roomUser => roomUser.some(u => u.userId == this.loggedUser.id))
          let userRooms = roomsPaginated.filter((room,index) => verifingUserRooms[index] == true)

            if(this.page == 1)
              this.rooms = userRooms;
            else{
              this.rooms = [...this.rooms, ...userRooms]
            }

            this.page = rooms.nextPage;
            this.hasNextPage = rooms.hasNextPage;

          await this.chatService.allConversations$.next(this.rooms);


        }
      });
  }

  getAllRooms(){
    // this.rooms = null
    // this.roomsSub = this.chatService.conversationsReturned
    //   .subscribe(async (rooms:ChatConversationsInterface) => {
    //      let allrooms = await rooms;
    //      this.rooms = allrooms
    // });

  }


  getItem(roomIndex){

    if(this.fulscream){
      this.conversationData.emit(roomIndex);
    }
  }

  onScrollDown() {
    if(this.hasNextPage){
      this.gtRooms(this.page);
    }
  }

  ngOnDestroy(): void {
    if(this.roomsSub)
      this.roomsSub.unsubscribe();
  }


}
